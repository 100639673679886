import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { getPlatforms } from '../../services/api';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { Platform } from '../../types/Game';

const PlatformListPage: React.FC = () => {
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const data = await getPlatforms();
        setPlatforms(data);
      } catch (error) {
        console.error('プラットフォーム一覧の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlatforms();
  }, []);

  if (loading) {
    return <LoadingPlaceholder type="card" count={12} />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ゲームプラットフォーム一覧
      </Typography>
      <Grid container spacing={3}>
        {platforms.map((platform) => (
          <Grid item xs={12} sm={6} md={4} key={platform.platform_id}>
            <Card>
              <CardActionArea component={Link} to={`/games/platform/${platform.platform_id}`}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {platform.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PlatformListPage;