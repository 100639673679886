import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Grid, Pagination, Box, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { getGamesByPlatform, getGamesByGenre, getGamesByFranchise, getGamesByCollection, getGamesByGameEngine, getGamesByTheme, getGamesByCompany, getBasicGameStatuses, getGamesByKeyword } from '../../services/api';
import { Game } from '../../types/Game';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import GameCard from '../../components/GameCard';
import { Helmet } from 'react-helmet-async';
import WikipediaViewer from '../../components/WikipediaViewer';
import Expandable from '../../components/common/Expandable';
import Advertisement from '../../components/advertisement/Ad_wide_728';
import RectangleAdvertisement from '../../components/advertisement/Ad_rectangle_300';
import AdvertisementResponsive from '../../components/advertisement/Ad_GA_res';

const GAMES_PER_PAGE = 30;

const GameListByCategory: React.FC = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [wikiSummary, setWikiSummary] = useState<string | null>(null);
  const [wikiOpen, setWikiOpen] = useState(false);
  const [hasWikiData, setHasWikiData] = useState(false);
  const [wikiLoading, setWikiLoading] = useState(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [japaneseTitle, setJapaneseTitle] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  // ゲームデータ取得後にステータスを一括取得
  useEffect(() => {
    const fetchGameStatuses = async () => {
      if (!isLoggedIn || games.length === 0) return;

      const gameIds = games.map(game => game.game_id);
      const statuses = await getBasicGameStatuses(gameIds);
      setGameStatuses(statuses);
    };

    fetchGameStatuses();
  }, [games, isLoggedIn]);

  // URLからページ番号を取得
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page') || '1', 10);

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      try {
        if (!id) {
          throw new Error('Invalid ID');
        }
        let response;
        switch (type) {
          case 'platform':
            response = await getGamesByPlatform(id, currentPage);
            setCategoryName(response.results.platform_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'genre':
            response = await getGamesByGenre(id, currentPage);
            setCategoryName(response.results.genre_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'franchise':
            response = await getGamesByFranchise(id, currentPage);
            setCategoryName(response.results.franchise_name);
            setJapaneseTitle(response.results.japanese_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'collection':
            response = await getGamesByCollection(id, currentPage);
            setCategoryName(response.results.collection_name);
            setJapaneseTitle(response.results.japanese_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'game-engine':
            response = await getGamesByGameEngine(id, currentPage);
            setCategoryName(response.results.game_engine_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'theme':
            response = await getGamesByTheme(id, currentPage);
            setCategoryName(response.results.theme_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'company':
            response = await getGamesByCompany(id, currentPage);
            setCategoryName(response.results.company_name);
            setJapaneseTitle(response.results.japanese_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          case 'keyword':
            response = await getGamesByKeyword(id, currentPage);
            setCategoryName(response.results.keyword_name);
            setGames(response.results.games);
            setTotalCount(response.count);
            break;
          default:
            throw new Error('Invalid category type');
        }
        setTotalPages(Math.ceil(response.count / GAMES_PER_PAGE));
      } catch (error) {
        console.error('ゲーム一覧の取得に失敗しました', error);
        setGames([]);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [id, type, currentPage]);

  useEffect(() => {
    if (categoryName || japaneseTitle) {
        setWikiLoading(true);
        const fetchWikiSummary = async () => {
            try {
                const searchName = japaneseTitle || categoryName; // japaneseTitleがあればそれを使用
                const cachedData = localStorage.getItem(`wiki_summary_${searchName}`);
                if (cachedData) {
                    const { summary, timestamp } = JSON.parse(cachedData);
                    const now = new Date().getTime();
                    if (now - timestamp < 24 * 60 * 60 * 1000) {
                        setWikiSummary(summary);
                        setHasWikiData(true);
                        setWikiLoading(false);
                        return;
                    }
                }

                const response = await fetch(`https://ja.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(searchName)}`);
                const data = await response.json();
                if (data.extract) {
                    setWikiSummary(data.extract);
                    setHasWikiData(true);
                    
                    const cacheData = {
                        summary: data.extract,
                        timestamp: new Date().getTime()
                    };
                    localStorage.setItem(`wiki_summary_${searchName}`, JSON.stringify(cacheData));
                } else {
                    setHasWikiData(false);
                }
            } catch (error) {
                console.error('Wikipedia APIからのデータ取得に失敗しました:', error);
                setHasWikiData(false);
            } finally {
                setWikiLoading(false);
            }
        };

        fetchWikiSummary();
    }
  }, [categoryName, japaneseTitle]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    navigate(`/games/${type}/${id}?page=${value}`);
  };

  return (
    <>
      <Helmet>
        <title>{`${categoryName} のゲーム一覧 | PULSE データベース`}</title>
      </Helmet>
      <Typography variant="h5" component="h1" gutterBottom>
        {(() => {
          if (['franchise', 'collection', 'company'].includes(type || '') && japaneseTitle) {
            return (
              <>
                {japaneseTitle}
                <Typography
                  component="span"
                  sx={{
                    ml: 1,
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    fontWeight: 'normal',
                    opacity: 0.8,
                    verticalAlign: 'middle'
                  }}
                >
                  {categoryName}
                </Typography>
                <Typography 
                  component="span" 
                  sx={{ 
                    ml: 1,
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    opacity: 0.7,
                    fontWeight: 'normal',
                    verticalAlign: 'middle'
                  }}
                >
                  • {totalCount}作品
                </Typography>
              </>
            );
          }
          return (
            <>
              {categoryName}
              <Typography 
                component="span" 
                sx={{ 
                  ml: 1,
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  opacity: 0.7,
                  fontWeight: 'normal',
                  verticalAlign: 'middle'
                }}
              >
                • {totalCount}作品
              </Typography>
            </>
          );
        })()}
      </Typography>

      <Grid container>
        <Grid item xs={12} md={8}>
          <Box sx={{ position: 'relative', mb: 3, minHeight: 60 }}>
            {wikiLoading ? (
              <>
                <Skeleton variant="text" width="100%" height={20} />
                <Skeleton variant="text" width="80%" height={20} />
              </>
            ) : wikiSummary ? (
              <>
                <Expandable maxLines={3} expandButtonStyle="icon" iconSize={18} iconPadding={4} iconColor="#ffffff" iconBgColor="rgba(0, 0, 0, 0.9)">
                  <Typography 
                    variant="body2" 
                    paragraph 
                    sx={{ 
                      color: 'text.secondary', 
                      fontSize: '0.7rem',
                      mb: 0
                    }}
                  >
                    {wikiSummary}
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: 'primary.main',
                        cursor: 'pointer',
                        ml: 1,
                        fontSize: 'inherit',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={() => setWikiOpen(true)}
                    >
                      詳細を見る
                    </Typography>
                  </Typography>
                </Expandable>
              </>
            ) : (
              <Typography 
                variant="body2" 
                paragraph 
                sx={{ 
                  color: 'text.secondary', 
                  fontSize: '0.7rem',
                  mb: 0,
                  opacity: 0.5
                }}
              >
                概要情報なし
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {loading ? (
        <LoadingPlaceholder type="card" count={GAMES_PER_PAGE} />
      ) : (
        <>
          <Grid container spacing={{ xs: 1, sm: 1.5 }}>
            {games.map((game, index) => (
              <React.Fragment key={game.game_id}>
                <Grid item xs={4} sm={3} md={2}>
                  <GameCard 
                    game={game} 
                    initialStatus={gameStatuses[game.game_id]}
                  />
                </Grid>
                {isMobile && index === 11 && (
                  <Grid item xs={12}>
                    <AdvertisementResponsive />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
          
          {isMobile ? <RectangleAdvertisement /> : <Advertisement />}
          
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
            />
          )}
        </>
      )}

      <WikipediaViewer
        open={wikiOpen}
        onClose={() => setWikiOpen(false)}
        title={japaneseTitle || categoryName}
        gameId={parseInt(id!, 10)}
      />
    </>
  );
};

export default GameListByCategory;