import React, { useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import StyledTextField from '../common/StyledTextField';
import TriviaEditConfirmDialog from './TriviaEditConfirmDialog';
import TriviaHistory from './TriviaHistory';

interface TriviaEditorProps {
  triviaId?: number;
  initialTitle: string;
  initialContent: string;
  initialSource?: string | null;
  onSubmit: (data: { title: string; content: string; source?: string }) => Promise<void>;
  onCancel?: () => void;
}

const TriviaEditor: React.FC<TriviaEditorProps> = ({
  triviaId,
  initialTitle,
  initialContent,
  initialSource = '',
  onSubmit,
  onCancel
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [content, setContent] = useState(initialContent);
  const [source, setSource] = useState(initialSource || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleSubmit = async () => {
    if (!content.trim()) return;
    setConfirmDialogOpen(true);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit({
        title: title.trim(),
        content: content.trim(),
        source: source.trim() || undefined,
      });
    } finally {
      setIsSubmitting(false);
      setConfirmDialogOpen(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: 2 }}>
        <StyledTextField
          label="タイトル"
          value={title}
          onChange={setTitle}
          multiline={false}
          required
          placeholder="トリビアのタイトルを入力してください"
        />

        <StyledTextField
          label="トリビア"
          value={content}
          onChange={setContent}
          required
          minRows={3}
          maxRows={6}
          placeholder="ゲームに関する豆知識や裏話を入力してください"
        />

        <StyledTextField
          label="出典"
          value={source}
          onChange={setSource}
          multiline={false}
          placeholder="公式サイト、インタビュー記事、書籍など"
        />

        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 1 }}>
          {onCancel && (
            <Button
              onClick={onCancel}
              disabled={isSubmitting}
              size="small"
              sx={{ color: 'text.secondary' }}
            >
              キャンセル
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!content.trim() || isSubmitting}
            size="small"
          >
            保存する
          </Button>
        </Box>

        {triviaId && (
          <>
            <Divider sx={{ my: 2 }} />
            <TriviaHistory 
              triviaId={triviaId} 
              currentTrivia={{
                title: initialTitle,
                content: initialContent,
                source: source
              }}
            />
          </>
        )}
      </Box>

      <TriviaEditConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirm}
        originalData={{
          title: initialTitle,
          content: initialContent,
          source: initialSource || null,
        }}
        newData={{
          title: title,
          content: content,
          source: source || null,
        }}
      />
    </>
  );
};

export default TriviaEditor; 