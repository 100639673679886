import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  useTheme,
  Skeleton
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ProjectCTAProps {
  loading?: boolean;
}

const ProjectCTA: React.FC<ProjectCTAProps> = ({ loading = false }) => {
  const theme = useTheme();

  if (loading) {
    return (
      <Box sx={{ 
        mt: 8, 
        py: 6, 
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        height: '646px',
        position: 'relative',
        overflow: 'hidden'
      }}>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      mt: 8, 
      py: 6, 
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      position: 'relative',
      overflow: 'hidden'
    }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6.5}>
            <Box 
              component="img"
              src="/image/pulse_cta.jpg"
              alt="プロジェクト紹介"
              sx={{
                width: '100%',
                borderRadius: 2,
                boxShadow: theme.shadows[4],
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <Typography variant="h4" gutterBottom>
              PULSEでゲームを記録しよう
            </Typography>
            <Typography color="text.secondary" paragraph>
              PULSEは、全てのゲームプレイヤーのための、ゲーム記録・管理プラットフォームです。
              これまでプレイしたゲームを集約し、あなただけのゲームライブラリを簡単に作成できます。
            </Typography>
            <Box 
              sx={{ 
                display: 'flex', 
                flexWrap: 'wrap',
                gap: 2, 
                mt: 3,
                '& > *': {
                  flexGrow: 1,
                  minWidth: '200px'
                }
              }}
            >
              <Button
                href="/login"
                variant="contained"
                startIcon={<LoginIcon />}
                size="large"
                sx={{
                  backgroundColor: '#24292f',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#2c3338'
                  }
                }}
              >
                無料でライブラリを開設
              </Button>

            </Box>
            <Box sx={{ mt: 2 }}>
              <List dense>
                {[
                  'プレイ履歴の記録',
                  '進行中のゲーム管理',
                  'ゲームリストやランキングの作成',
                  '気になるタイトルの保存',
                  'ゲーマーとしての経歴を表現する',
                ].map((feature, index) => (
                  <ListItem key={index} disableGutters>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <CheckCircleIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProjectCTA; 