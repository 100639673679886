import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Container, 
  Avatar, 
  Chip, 
  Grid,
  Link as MuiLink,
  Paper,
  useTheme,
  Button
} from '@mui/material';
import { getReviewDetail } from '../services/api';
import { ReviewDetail } from '../types/Review';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ShareButtons from '../components/common/ShareButtons';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { likeReview, unlikeReview } from '../services/api';

interface ScoreTag {
  id: number;
  label: string;
}

const scoreTags: ScoreTag[] = [
  { id: 1, label: '趣味趣向補正' },
  { id: 2, label: '思い出補正' },
  { id: 3, label: 'IP・シリーズ補正' },
  { id: 4, label: 'デベロッパー補正' },
  { id: 5, label: 'ソフトウェアバージョン補正' },
  { id: 6, label: 'マルチプレイ補正' },
  { id: 7, label: 'エントリー補正' },
  { id: 8, label: 'コンテキスト補正' },
  { id: 9, label: '下方バイアス' },
  { id: 0, label: 'その他の上方補正要因' },
];

const GameReviewDetailPage: React.FC = () => {
  const { gameId, reviewId } = useParams<{ gameId: string; reviewId: string }>();
  const [review, setReview] = useState<ReviewDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [isLiked, setIsLiked] = useState(review?.is_liked || false);
  const [likeCount, setLikeCount] = useState(review?.like_count || 0);
  const isAuthenticated = !!localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchReviewDetail = async () => {
      try {
        const reviewData = await getReviewDetail(Number(gameId), Number(reviewId));
        setReview(reviewData);
      } catch (error) {
        console.error('レビュー詳細の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviewDetail();
  }, [gameId, reviewId]);

  useEffect(() => {
    if (review) {
      setIsLiked(review.is_liked);
      setLikeCount(review.like_count);
    }
  }, [review]);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const truncateText = (text: string, maxLength: number = 50) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }

    try {
      if (isLiked) {
        const response = await unlikeReview(Number(reviewId));
        setIsLiked(false);
        setLikeCount(response.data.count);
      } else {
        const response = await likeReview(Number(reviewId));
        setIsLiked(true);
        setLikeCount(response.data.count);
      }
    } catch (error) {
      console.error('イイね処理に失敗しました', error);
    }
  };

  if (loading) return <Typography>読み込み中...</Typography>;
  if (!review) return <Typography>レビューが見つかりません</Typography>;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={{ xs: 2, md: 4 }}>
        {/* ゲーム情報ブロック */}
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={0} 
            sx={{ 
              borderRadius: 3,
              overflow: 'hidden',
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 1 }} sx={{ p: 2 }}>
              {/* ジャケット画像 */}
              <Grid item xs={3} md={12}>
                <Box
                  component={RouterLink}
                  to={`/game/${gameId}`}
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    '& img': {
                      width: '100%',
                      height: 'auto',
                      borderRadius: 1,
                    }
                  }}
                >
                  <img
                    src={review.game.cover || '/image/no_image.png'}
                    alt={review.game.name}
                  />
                </Box>
              </Grid>
              
              {/* ゲーム情報 */}
              <Grid item xs={9} md={12}>
                <Box
                  component={RouterLink}
                  to={`/game/${gameId}`}
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: 'block',
                    '&:hover': {
                      '& .game-title': {
                        color: 'primary.main'
                      }
                    }
                  }}
                >
                  <Typography 
                    variant="body2" 
                    component="h2" 
                    gutterBottom
                    className="game-title"
                  >
                    {review.game.japanese_name || review.game.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {review.game.first_release_date && 
                      new Date(review.game.first_release_date).getFullYear()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* レビュー詳細ブロック */}
        <Grid item xs={12} md={8}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: { xs: 2, sm: 4 },
              borderRadius: 3,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            {/* ユーザー情報 */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Avatar 
                src={review.avatar || undefined} 
                alt={review.username} 
                sx={{ width: 48, height: 48 }} 
              />
              <Box sx={{ ml: 2 }}>
                <MuiLink
                  component={RouterLink}
                  to={`/profile/${review.username}`}
                  variant="body1"
                  sx={{ 
                    textDecoration: 'none',
                    color: 'text.primary',
                    fontWeight: 'bold',
                    '&:hover': {
                      color: 'primary.main',
                    }
                  }}
                >
                  {review.display_name || review.username}
                </MuiLink>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', columnGap: 2, rowGap: 0, mt: 0.5 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                    投稿日：{formatDate(review.created_at)}
                  </Typography>
                  {review.updated_at && review.updated_at !== review.created_at && (
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                      更新日：{formatDate(review.updated_at)}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            {/* スコアとタグ */}
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 3,
                gap: 2,
              }}
            >
              {review.score !== null && (
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    background: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: 2,
                    px: 1.5,
                    py: 1,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  }}
                >
                  <StarRoundedIcon 
                    sx={{ 
                      fontSize: '1.5rem',
                      color: '#FFD700',
                      filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      ml: 1,
                      color: 'white',
                      fontWeight: 'bold',
                      textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    }}
                  >
                    {review.score.toFixed(1)}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {review.score_tags?.map((tagId: number) => {
                  const tag = scoreTags.find((t) => t.id === tagId);
                  return tag && (
                    <Chip
                      key={tag.id}
                      icon={
                        tag.id === 9 ? (
                          <KeyboardDoubleArrowDownIcon style={{ color: '#79bcf3' }} />
                        ) : (
                          <KeyboardDoubleArrowUpIcon style={{ color: '#f37979' }} />
                        )
                      }
                      label={tag.label}
                      size="small"
                      variant="outlined"
                      sx={{ 
                        '& .MuiChip-icon': { fontSize: '0.7rem' },
                        '& .MuiChip-label': { 
                          fontSize: '0.6rem',
    
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            {/* プレイ情報 */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1, columnGap: 2, mb: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1 }}>
                  プレイ時間：{review.play_time ? `${review.play_time}時間` : '-'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1 }}>
                プレイ時期：{review.play_start_date || '-'} - {review.play_end_date || '-'}
                </Typography>
              </Box>
            </Box>

            {/* レビュー本文 */}
            <Typography 
              variant="body1" 
              sx={{ 
                lineHeight: 1.8,
                color: 'text.primary',
                whiteSpace: 'pre-wrap',
                mb: 3
              }}
            >
              {review.review_text}
            </Typography>

            {/* シェアボタン */}
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <Button
                onClick={handleLikeClick}
                startIcon={isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}

                color={isLiked ? "primary" : "inherit"}
                sx={{ 
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                {likeCount}
              </Button>
              <ShareButtons
                text={`${review.game.japanese_name || review.game.name}のレビュー by ${review.display_name || review.username}

${truncateText(review.review_text)}`}
                url={`/game/${gameId}/reviews/${reviewId}`}
                buttonText='レビューを共有'
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GameReviewDetailPage; 