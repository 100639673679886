import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, CircularProgress, useTheme, Box, Link, Collapse, Button, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DOMPurify from 'dompurify';
import Advertisement336 from './advertisement/Ad_GA_336'; // 広告コンポーネントをインポート
import ReactDOM from 'react-dom/client';

interface WikipediaViewerProps {
  open: boolean;
  onClose: () => void;
  title: string;
  gameId: number; // gameIdを追加
}

const WikipediaViewer: React.FC<WikipediaViewerProps> = ({ open, onClose, title, gameId }) => {
  const [content, setContent] = useState<string>(''); // 全体のコンテンツ
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [toc, setToc] = useState<string>(''); // 目次用の状態を追加
  const [infoboxContent, setInfoboxContent] = useState<string>(''); // infoboxの内容を管理する状態を追加
  const [showInfobox, setShowInfobox] = useState<boolean>(false); // infoboxの表示状態を追加
  const theme = useTheme();
  const [currentSection, setCurrentSection] = useState<string>('');
  const [showFullToc, setShowFullToc] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) {
      setLoading(true);
      setError(null);
      fetch(`https://ja.wikipedia.org/api/rest_v1/page/html/${encodeURIComponent(title)}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('ページが見つかりません');
          }
          return response.text();
        })
        .then(data => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, 'text/html');
          
          // 不要要素を削除
          const elementsToRemove = doc.querySelectorAll('.mw-empty-elt, .mw-editsection, .error, .noprint, .pathnavbox, .navbox, .navbar, [role="presentation"]');
          elementsToRemove.forEach(el => el.remove());

          // セクションを取得し、適度な間隔で広告を挿入
          const sections = doc.querySelectorAll('section[data-mw-section-id]');
          sections.forEach((section, index) => {
            // PCとスマホで異なる広告挿入位置を設定
            const adPositions = isMobile ? [1, 4, 7, 11, 15] : [1, 5, 10, 16];
            if (adPositions.includes(Number(section.getAttribute('data-mw-section-id')))) {
              const adDiv = doc.createElement('div');
              adDiv.id = `ad-container-${index}`;
              adDiv.innerHTML = '<Advertisement336 />';
              section.parentNode?.insertBefore(adDiv, section.nextSibling);
            }
          });

          // 外部リンクセクション以降を削除
          const externalLinks = doc.querySelector('#外部リンク') || doc.querySelector('#関連項目') || doc.querySelector('#脚注');
          if (externalLinks) {
            let currentNode = externalLinks;
            while (currentNode.nextSibling) {
              currentNode.nextSibling.remove();
            }
            currentNode.remove();
          }

          // 目次を取得
          const tocElements = doc.querySelectorAll('h2'); // h2のみを目次として取得
          const tocList = Array.from(tocElements).map((el, index) => {
            const id = `toc-${index}`;
            el.id = id; // 各見出しにIDを付与
            return `<li class="h2"><a href="#${id}" onClick="event.preventDefault(); const element = document.getElementById('${id}'); if (element) { element.scrollIntoView(); }">${el.textContent}</a></li>`; // h2のクラスを追加し、クリックイベントを追加
          }).join('');
          setToc(`<ul class="toc">${tocList}</ul>`); // 目次をHTMLとして保存

          // HTMLをクリーンにし、リンクを変換
          const cleanHtml = DOMPurify.sanitize(doc.body.innerHTML);
          const updatedHtml = cleanHtml
            .replace(/href="\.\/([^#"]+)#([^"]+)"/g, (match, p1, p2) => {
              return `href="/game/${gameId}#${p2}" onClick="event.preventDefault(); const element = document.getElementById('${p2}'); if (element) { element.scrollIntoView(); }"`;
            })
            .replace(/href="\.\/([^"]+)"/g, (match, p1) => {
              return `href="https://ja.wikipedia.org/wiki/${p1}" target="_blank" rel="noopener noreferrer"`;
            })
            .replace(/href="(https?:\/\/[^#"]+)"/g, (match, p1) => {
              return `href="${p1}" target="_blank" rel="noopener noreferrer"`;
            });

          // 変換後のHTMLを再度DOMに変換
          const updatedDoc = parser.parseFromString(updatedHtml, 'text/html');

          // infoboxを取得
          const infobox = updatedDoc.querySelector('.infobox');
          if (infobox) {
            setInfoboxContent(infobox.outerHTML);
            infobox.remove(); // infoboxを全体から削除
          }

          setContent(updatedDoc.body.innerHTML);
          setLoading(false);
        })
        .catch(error => {
          console.error('Wikipedia APIからのデータ取得に失敗しました:', error);
          setError(error.message);
          setLoading(false);
        });
    }
  }, [open, title]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentSection(entry.target.textContent || '');
          }
        });
      },
      { threshold: 0.5, rootMargin: '-60px 0px 0px 0px' }
    );

    const headings = contentRef.current?.querySelectorAll('h2, h3');
    headings?.forEach((heading) => observer.observe(heading));

    return () => observer.disconnect();
  }, [content]);

  useEffect(() => {
    // 全ての広告コンテナを取得して広告コンポーネントを挿入
    const adContainers = contentRef.current?.querySelectorAll('[id^="ad-container-"]');
    adContainers?.forEach(container => {
      const root = ReactDOM.createRoot(container);
      root.render(<Advertisement336 />);
    });
  }, [content]);

  const handleTocClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
    setShowFullToc(false);
  };

  const renderToc = () => (
    <Box>
      <Typography 
        variant="h6" 
        onClick={() => setShowFullToc(!showFullToc)} 
        sx={{ 
          cursor: 'pointer', 
          display: 'flex', 
          alignItems: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {currentSection || '目次'}
        <ExpandMoreIcon sx={{ ml: 1, transform: showFullToc ? 'rotate(180deg)' : 'none', flexShrink: 0 }} />
      </Typography>
      <Collapse in={showFullToc}>
        <Box
          dangerouslySetInnerHTML={{ __html: toc }}
          sx={{
            '& ul': { paddingLeft: '0' },
            '& li': { marginBottom: '0.25rem' },
            '& a': {
              color: theme.palette.text.secondary + ' !important',
              textDecoration: 'none',
            },
          }}
          onClick={(e) => {
            const target = e.target as HTMLAnchorElement;
            if (target.tagName === 'A') {
              e.preventDefault();
              handleTocClick(target.getAttribute('href')?.slice(1) || '');
            }
          }}
        />
      </Collapse>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          width: '100%',
          maxWidth: isMobile ? '100%' : '1280px',
          borderRadius: isMobile ? '0' : '12px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isMobile && (
        <Box sx={{ 
          position: 'sticky', 
          top: 0, 
          zIndex: 1, 
          backgroundColor: theme.palette.background.default, 
          borderBottom: `1px solid ${theme.palette.divider}`,
          padding: '8px 16px',
        }}>
          {renderToc()}
        </Box>
      )}
      <DialogContent dividers sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', 
        backgroundColor: theme.palette.background.default,
        padding: isMobile ? '16px' : '24px', 
      }}>
        {!isMobile && (
          <Box sx={{ flex: '0 0 200px', position: 'sticky', top: 0, maxHeight: '80vh', overflowY: 'auto', marginRight: '16px' }}>
            <Typography variant="h6">目次</Typography>
            <Box 
              dangerouslySetInnerHTML={{ __html: toc }} 
              sx={{
                '& ul': { paddingLeft: '0' },
                '& li': { marginBottom: '0.25rem' },
                '& a': {
                  color: theme.palette.text.secondary + ' !important',
                  textDecoration: 'none',
                },
              }} 
            />
          </Box>
        )}
        <Box sx={{ 
          flex: 1, 
          maxWidth: isMobile ? '100%' : 'calc(100% - 216px)', 
        }} ref={contentRef}>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box
              sx={{
                '& a': {
                  color: theme.palette.text.secondary, // リンクを薄い色に設定
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline', // ホバー時に下線を表示
                  },
                },
                '& h2': {
                  fontSize: { xs: '1.3rem', sm: '1.4rem' },
                  fontWeight: 'normal',
                  marginTop: '2rem',
                  marginBottom: '1rem',
                  borderBottom: '2px solid', // 下線を追加
                  borderColor: theme.palette.divider, // 下線の色をテーマに合わせる
                  paddingBottom: '0.25rem', // 下線とテキストの間に余白を追加
                },
                '& h3': {
                  fontSize: { xs: '1.1rem', sm: '1.2rem' },
                  fontWeight: 'bold',
                  marginTop: '1rem',
                  marginBottom: '0.5rem',
                },
                '& h4': {
                  fontSize: '.95rem',
                  fontWeight: 'bold',
                  marginTop: '1rem',
                  marginBottom: '0.8rem',
                },
                '& dt': {
                  marginTop: '0.5rem',
                },
                '& ul': {
                  paddingLeft: '1rem',
                },
                '& li': {
                  marginBottom: '0.25rem', // リストアイテムの間隔を調整
                },
                '& p': {
                  fontSize: { xs: '0.85rem', sm: '0.95rem' },
                  marginBottom: '0.5rem',
                  lineHeight: '1.6', // 行間を1.5に設定
                },
                '& table': {
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '1rem',
                  display: 'block',
                  overflowX: 'auto',
                  maxWidth: '100%',
                },
                '& th, & td': {
                  border: '1px solid',
                  borderColor: theme.palette.divider,
                  padding: '8px',
                  textAlign: 'left',
                  backgroundColor: theme.palette.background.paper,
                  fontSize: { xs: '0.65rem', sm: '0.8rem' },
                  minWidth: '100px',
                },
                '& th': {
                  backgroundColor: theme.palette.background.default + ' !important',
                  color: theme.palette.text.primary + ' !important',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <Collapse in={showInfobox}>
                <div dangerouslySetInnerHTML={{ __html: infoboxContent }} />
              </Collapse>
              <Button onClick={() => setShowInfobox(!showInfobox)}>
              infoboxを{showInfobox ? '閉じる' : '開く'} 
              </Button>
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'right' }}>
                <Link href={`https://ja.wikipedia.org/wiki/${encodeURIComponent(title)}`} target="_blank" rel="noopener noreferrer" sx={{ fontSize: '0.8rem' }}>
                  Source: Wikipedia
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WikipediaViewer;
