import React from 'react';
import { 
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Box,
  PopoverProps,
  SxProps,
  Theme,
  Divider
} from '@mui/material';
import { useState } from 'react';

interface MenuOption {
  icon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  iconColor?: string;
  items?: {
    icon?: React.ReactNode;
    label: string;
    onClick: () => void;
    iconColor?: string;
  }[];
}

interface PopoverMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  title?: string;
  options: MenuOption[];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  sx?: SxProps<Theme>;
}

const PopoverMenu: React.FC<PopoverMenuProps> = ({
  open,
  anchorEl,
  onClose,
  title,
  options,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  sx
}) => {
  const [expandedMenuIndex, setExpandedMenuIndex] = useState<number | null>(null);

  const handleMenuItemClick = (option: MenuOption, index: number) => {
    if (option.items) {
      setExpandedMenuIndex(expandedMenuIndex === index ? null : index);
    } else {
      option.onClick?.();
    }
  };

  const renderMenuItems = (options: MenuOption[]) => {
    return options.map((option, index) => (
      <React.Fragment key={index}>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleMenuItemClick(option, index)}
            sx={{ 
              py: 0.5,
              borderRadius: 1,
              '&:hover': { backgroundColor: 'action.hover' }
            }}
          >
            <ListItemIcon sx={{ minWidth: 36, color: option.iconColor }}>
              {React.cloneElement(option.icon as React.ReactElement, { 
                sx: { fontSize: '1.2rem' } 
              })}
            </ListItemIcon>
            <ListItemText 
              primary={option.label} 
              primaryTypographyProps={{ 
                variant: 'body2',
                sx: { fontWeight: 500 }
              }}
            />
          </ListItemButton>
        </ListItem>
        {option.items && expandedMenuIndex === index && (
          <>
            {option.items.map((subOption, subIndex) => (
              <ListItem key={`${index}-${subIndex}`} disablePadding>
                <ListItemButton 
                  onClick={subOption.onClick}
                  sx={{ 
                    py: 0.5,
                    pl: 4,
                    borderRadius: 1,
                    '&:hover': { backgroundColor: 'action.hover' }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 36, color: subOption.iconColor }}>
                    {React.cloneElement(subOption.icon as React.ReactElement, { 
                      sx: { fontSize: '1.2rem' } 
                    })}
                  </ListItemIcon>
                  <ListItemText 
                    primary={subOption.label} 
                    primaryTypographyProps={{ 
                      variant: 'body2',
                      sx: { fontWeight: 500, fontSize: '0.75rem' }
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
      </React.Fragment>
    ));
  };

  // Popoverが閉じられたときにexpandedMenuIndexをリセット
  const handlePopoverClose = () => {
    setExpandedMenuIndex(null);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        sx: {
          minWidth: '200px',
          maxWidth: '250px',
          boxShadow: '0 8px 24px rgba(140,149,159,0.2)',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '6px',
          mt: 0.5,
          ...sx
        }
      }}
    >
      <Box sx={{ p: 1 }}>
        {title && (
          <Typography variant="subtitle2" sx={{ px: 1, py: 0.5 }}>
            {title}
          </Typography>
        )}
        <List sx={{ p: 0 }}>
          {renderMenuItems(options)}
        </List>
      </Box>
    </Popover>
  );
};

export default PopoverMenu; 