import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Tooltip, Box, SvgIcon, SvgIconProps } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Game } from '../types/Game';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { setGameStatus } from '../services/api';
import ConfirmDialog from './dialogs/ConfirmDialog';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';
import PopoverMenu from './common/PopoverMenu';
import { getPlatformDisplayText } from '../utils/PlatformList';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import GameReviewDialog from './GameReviewDialog';
import { GameReviewData } from '../types/Review';

interface GameCardProps {
  game: Game;
  mode?: 'profile' | 'public';
  username?: string;
  initialStatus?: number | null;
  initialScore?: number | null;
  initialReviewText?: string | null;
  initialPlayStartDate?: string | null;
  initialPlayEndDate?: string | null;
  initialPlayTime?: number | null;
  initialSubStatus?: number[] | null;
  initialScoreTags?: string[] | null;
  initialPlatform?: number[] | null;
}

const GradientBeenhereIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <defs>
      <linearGradient id="beenhereGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#2088ff" />
        <stop offset="100%" stopColor="#a455ff" />
      </linearGradient>
    </defs>
    <BeenhereIcon sx={{ fill: 'url(#beenhereGradient)' }} />
  </SvgIcon>
);

const GameCard: React.FC<GameCardProps> = ({ 
  game, 
  mode, 
  username,
  initialStatus,
  initialScore,
  initialReviewText,
  initialPlayStartDate,
  initialPlayEndDate,
  initialPlayTime,
  initialSubStatus,
  initialScoreTags,
  initialPlatform
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState<number | null>(initialStatus ?? null);
  const [isInitialStatus, setIsInitialStatus] = useState<boolean>(true);
  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [score, setScore] = useState<number | null>(initialScore ?? null);
  const [reviewText, setReviewText] = useState<string | null>(initialReviewText ?? null);
  const [playStartDate, setPlayStartDate] = useState<string | null>(initialPlayStartDate ?? null);
  const [playEndDate, setPlayEndDate] = useState<string | null>(initialPlayEndDate ?? null);
  const [playTime, setPlayTime] = useState<number | null>(initialPlayTime ?? null);
  const navigate = useNavigate();
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [subStatus, setSubStatus] = useState<number[] | null>(initialSubStatus ?? null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  React.useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    checkLoginStatus();
  }, []);

  React.useEffect(() => {
    setStatus(initialStatus ?? null);
    setIsInitialStatus(true);
    setScore(initialScore ?? null);
    setReviewText(initialReviewText ?? null);
    setPlayStartDate(initialPlayStartDate ?? null);
    setPlayEndDate(initialPlayEndDate ?? null);
    setPlayTime(initialPlayTime ?? null);
    setSubStatus(initialSubStatus ?? null);
  }, [
    initialStatus,
    initialScore,
    initialReviewText,
    initialPlayStartDate,
    initialPlayEndDate,
    initialPlayTime,
    initialSubStatus
  ]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (mode === 'public') return; // パブリックモードでは操作を無効化
    setAnchorEl(event.currentTarget);
    setIsStatusChanging(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsStatusChanging(false);
  };

  const handleStatusChange = async (newStatus: number | null) => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    // 削除時は確認ダイアログを表示
    if (newStatus === null) {
      setOpenDeleteConfirmDialog(true);
      return;
    }

    try {
      await setGameStatus(game.game_id, newStatus);
      setStatus(newStatus);
      setIsInitialStatus(false);
      
      // 初回のステータス設定時のみダイアログを表示（status === nullの場合）
      if (mode !== 'profile' && mode !== 'public' && 
          newStatus !== null && 
          [1, 2, 3].includes(newStatus) && 
          status === null) {
        setOpenReviewDialog(true);
      }
    } catch (error: any) {
      setErrorMessage(error.message || 'エラーが発生しました');
      setOpenErrorDialog(true);
    }
    handleClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      await setGameStatus(game.game_id, null);
      setStatus(null);
    } catch (error: any) {
      setErrorMessage(error.message || 'エラーが発生しました');
      setOpenErrorDialog(true);
    }
    setOpenDeleteConfirmDialog(false);
    handleClose();
  };

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isStatusChanging) {
      navigate(`/game/${game.game_id}`);
    }
  };

  const getStatusIcon = (statusCode: number | null) => {
    switch (statusCode) {
      case 1: return <CheckCircleOutlineIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 2: return <PlayCircleOutlineIcon fontSize="small" sx={{ color: 'info.main' }} />;
      case 3: return <PauseCircleOutlineIcon fontSize="small" sx={{ color: 'warning.main' }} />;
      case 4: return <NotStartedOutlinedIcon fontSize="small" sx={{ color: '#b99a71' }} />;
      case 5: return <StarBorderIcon fontSize="small" sx={{ color: 'secondary.main' }} />;
      default: return <AddCircleOutlineIcon fontSize="small" sx={{ color: 'text.secondary' }} />;
    }
  };

  const getStatusText = () => {
    switch (status) {
      case 1: return 'プレイ済み';
      case 2: return 'プレイ中';
      case 3: return '中断';
      case 4: return '積みゲー';
      case 5: return '気になる';
      default: return 'ステータスを設定';
    }
  };

  const renderPlayInfo = () => {
    if (mode !== 'profile' && mode !== 'public') return null;

    if (playStartDate) {
      const startDate = new Date(playStartDate);
      const formattedDate = `${startDate.getFullYear()}/${String(startDate.getMonth() + 1).padStart(2, '0')}`;

      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'flex-start', sm: 'center' }, 
          gap: { xs: '0.1em', sm: '0.5em' },
          minWidth: 0,
          flex: 1,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
            <PlayCircleOutlineIcon sx={{ mr: 0.25, color: 'text.secondary', flexShrink: 0, fontSize: { xs: '0.85rem', sm: '1rem' } }} />
            <Typography variant="caption" sx={{ lineHeight: 1, color: 'text.secondary', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {formattedDate}
            </Typography>
          </Box>
          {playTime && (
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
              <TimerOutlinedIcon sx={{ mr: 0.25, color: 'text.secondary', flexShrink: 0, fontSize: { xs: '0.85rem', sm: '1rem' } }} />
              <Typography variant="caption" sx={{ lineHeight: 1, color: 'text.secondary', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {playTime}h
              </Typography>
            </Box>
          )}
        </Box>
      );
    }

    // プレイ期間や時間がない場合は発売年を表示
    return (
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {game.first_release_date ? new Date(game.first_release_date).getFullYear() : '不明'}
      </Typography>
    );
  };

  const isDevelopment = process.env.NODE_ENV === 'development';

  const renderReplayIcon = () => {
    if (!isDevelopment || mode !== 'profile' && mode !== 'public' || !subStatus || !subStatus.includes(1)) return null;

    return (
      <Box
        sx={{
          background: 'linear-gradient(45deg, #f0f0f0d8, #bebebed2)',
          borderRadius: '6px',
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 0.5,
        }}
      >
        <Tooltip title="再プレイ" arrow>
          <RefreshIcon sx={{ color: '#383838', fontSize: '0.9rem' }} />
        </Tooltip>
      </Box>
    );
  };

  const renderAchievementIcon = () => {
    if (!isDevelopment || mode !== 'profile' && mode !== 'public' || !subStatus || !subStatus.includes(7)) return null;

    return (
      <Box
        sx={{
          background: 'linear-gradient(45deg, #2088ffc4, #a455ffa4)',
          borderRadius: '6px',
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 0.5,
        }}
      >
        <Tooltip title="実績コンプリート" arrow>
          <EmojiEventsIcon sx={{ color: 'white', fontSize: { xs: '0.9rem', sm: '1rem' } }} />
        </Tooltip>
      </Box>
    );
  };

  const hasUpwardBias = (scoreTags: string[] | null | undefined) => {
    if (!scoreTags) return false;
    const tags = scoreTags.map(Number);
    // 9が下方バイアス、それ以外は上方バイアス
    return tags.some(tag => tag !== 9);
  };

  const renderScore = () => {
    if (score === null || score === undefined || mode !== 'profile' && mode !== 'public') return null;

    const isUpwardBiased = hasUpwardBias(initialScoreTags);

    return (
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          borderRadius: '6px',
          padding: '2px 6px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isUpwardBiased ? (
          <KeyboardDoubleArrowUpIcon sx={{ color: 'gold', fontSize: '0.9rem', mr: 0.5 }} />
        ) : (
          <StarIcon sx={{ color: 'gold', fontSize: '0.9rem', mr: 0.5 }} />
        )}
        <Typography 
          variant="caption" 
          sx={{ 
            color: 'white', 
            fontWeight: 'bold', 
            lineHeight: 1.2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {typeof score === 'number' ? score.toFixed(1) : score}
        </Typography>
      </Box>
    );
  };

  const menuOptions = [
    {
      icon: <CheckCircleOutlineIcon />,
      label: 'プレイ済み',
      onClick: () => handleStatusChange(1),
      iconColor: 'primary.main'
    },
    {
      icon: <PlayCircleOutlineIcon />,
      label: 'プレイ中',
      onClick: () => handleStatusChange(2),
      iconColor: 'info.main'
    },
    {
      icon: <PauseCircleOutlineIcon />,
      label: '中断',
      onClick: () => handleStatusChange(3),
      iconColor: 'warning.main'
    },
    {
      icon: <NotStartedOutlinedIcon />,
      label: '積みゲー',
      onClick: () => handleStatusChange(4),
      iconColor: '#b99a71'
    },
    {
      icon: <StarBorderIcon />,
      label: '気になる',
      onClick: () => handleStatusChange(5),
      iconColor: 'secondary.main'
    },
    ...(status !== null ? [{
      icon: <RemoveCircleOutlineIcon />,
      label: '削除',
      onClick: () => handleStatusChange(null),
      iconColor: 'error.main'
    }] : [])
  ];

  const renderPlatformInfo = () => {
    if (!initialPlatform || mode !== 'profile' && mode !== 'public') return null;

    return (
      <Box
        sx={{
          position: 'absolute',
          maxWidth: { xs: '45%', sm: '70%' },
          bottom: 4,
          left: 4,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          borderRadius: '4px',
          padding: '0px 4px',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          backdropFilter: 'blur(4px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Typography 
          variant="caption" 
          sx={{ 
            color: 'white',
            fontSize: { xs: '0.5rem', sm: '0.6rem' },
            fontWeight: 'medium',
            lineHeight: 1.2,
            opacity: 0.9
          }}
        >
          {getPlatformDisplayText(initialPlatform)}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box
        component={RouterLink}
        to={`/game/${game.game_id}`}
        sx={{
          textDecoration: 'none',
          display: 'block',
          height: '100%',
        }}
        onClick={(e) => {
          if (isStatusChanging) {
            e.preventDefault();
          }
        }}
      >
        <Card 
          elevation={0}
          sx={{ 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            '&:hover': {
              transform: { xs: 'none', lg: 'translateY(-4px)' },
              boxShadow: 'none',
            },
            backgroundColor: 'background.default',
            borderRadius: '8px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <CardMedia
            component="div"
            sx={{
              height: 0,
              paddingTop: '133.33%', // 3:4のアスペクト比
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '8px',
              position: 'relative',
            }}
            image={game.cover || '/image/no_image.png'}
          >
            {renderPlatformInfo()}
            <Box
              sx={{
                position: 'absolute',
                top: 4,
                left: 4,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {renderAchievementIcon()}
              {renderReplayIcon()}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 4,
                right: 4,
              }}
            >
              {renderScore()}
            </Box>
          </CardMedia>
          <CardContent sx={{ flexGrow: 1, p: 0, pt: 1, backgroundColor: 'background.default', '&:last-child': { pb: 1.5 } }}>
            <Typography variant="subtitle2" component="div" noWrap sx={{ 
              fontSize: { xs: '0.65rem', sm: '0.75rem' },
              fontWeight: '600', 
              mb: 0 
            }}>
              {game.japanese_name || game.name}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              minWidth: 0
            }}>
              {(mode === 'profile' || mode === 'public') ? (
                renderPlayInfo()
              ) : (
                <Typography variant="caption" color="text.secondary" sx={{ flex: 1, minWidth: 0 }}>
                  {game.first_release_date ? new Date(game.first_release_date).getFullYear() : '不明'}
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, flexShrink: 0 }}>
                <Tooltip title={getStatusText()} arrow>
                  <IconButton
                    size="small"
                    onClick={handleClick}
                    aria-label={getStatusText()}
                    sx={{ p: 0 }}
                  >
                    {getStatusIcon(status)}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </CardContent>
          <PopoverMenu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            options={menuOptions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              ml: 1,
              mt: 1,
            }}
          />
        </Card>
      </Box>
      <ConfirmDialog
        open={openDeleteConfirmDialog}
        title="ステータス削除の確認"
        message="ステータスを削除すると、レビューとスコアも削除されます。本当に削除しますか？"
        onConfirm={handleDeleteConfirm}
        onCancel={() => setOpenDeleteConfirmDialog(false)}
        confirmText="削除"
        cancelText="キャンセル"
      />
      <Dialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
      >
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      {openReviewDialog && (
        <GameReviewDialog
          open={openReviewDialog}
          onClose={() => setOpenReviewDialog(false)}
          gameId={game.game_id}
          initialData={{
            score: null,
            reviewText: null,
            playStartDate: null,
            playEndDate: null,
            playTime: null,
            scoreTags: [],
            platform: []
          }}
          platforms={[]}
          releaseDate={game.first_release_date || undefined}
          onReviewSaved={(reviewData: GameReviewData) => {
            setIsInitialStatus(false);
            setOpenReviewDialog(false);
          }}
        />
      )}
    </>
  );
};

export default GameCard;
