import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, IconButton, Box, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { addGameVideo } from '../../services/api';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { isAuthenticated } from '../../services/auth';
import LoginRequiredDialog from '../dialogs/LoginRequiredDialog';
import { useLocation } from 'react-router-dom';

interface VideoUploadButtonProps {
  gameId: number;
  onUploadComplete?: () => void;
  onError?: (error: string) => void;
}

const VideoUploadButton: React.FC<VideoUploadButtonProps> = ({ gameId, onUploadComplete, onError }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState<number>(1);
  const [publishDate, setPublishDate] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  const [urlError, setUrlError] = useState<string>('');
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const location = useLocation();

  const extractYoutubeId = (url: string): string => {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : url;
  };

  const isValidYoutubeId = (id: string): boolean => {
    return id.length === 11;
  };

  const handleYoutubeIdChange = async (value: string) => {
    setYoutubeId(value);
    setUrlError('');
    
    if (!value) {
      setPreviewUrl('');
      setShowPreviewVideo(false);
      return;
    }

    const extractedId = extractYoutubeId(value);
    if (isValidYoutubeId(extractedId)) {
      setPreviewUrl(`https://img.youtube.com/vi/${extractedId}/maxresdefault.jpg`);
      setShowPreviewVideo(true);
      
      // YouTubeの動画情報を取得
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${extractedId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
        );
        const data = await response.json();
        
        if (data.items && data.items.length > 0) {
          const snippet = data.items[0].snippet;
          setTitle(snippet.title);
          setDescription(snippet.description);
          
          // 公開日をYYYY-MM-DD形式に変換
          const publishDate = new Date(snippet.publishedAt);
          const formattedDate = publishDate.toISOString().split('T')[0];
          setPublishDate(formattedDate);
        }
      } catch (error) {
        console.error('YouTube動画情報の取得に失敗しました:', error);
      }
    } else {
      setUrlError('有効なYouTube URLまたはIDを入力してください');
      setPreviewUrl('');
      setShowPreviewVideo(false);
    }
  };

  const handleSubmit = async () => {
    if (!youtubeId || !title) return;

    setLoading(true);
    try {
      const extractedYoutubeId = extractYoutubeId(youtubeId);
      await addGameVideo({
        game_id: gameId,
        youtube_id: extractedYoutubeId,
        title,
        description,
        type,
        publish_date: publishDate || null,
      });

      if (onUploadComplete) {
        onUploadComplete();
      }
      setOpen(false);
      resetForm();
    } catch (error) {
      console.error('動画の追加に失敗しました:', error);
      if (onError) {
        onError('動画の追加に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setYoutubeId('');
    setTitle('');
    setDescription('');
    setType(1);
    setPublishDate('');
    setPreviewUrl('');
  };

  const isFormValid = (): boolean => {
    const extractedId = extractYoutubeId(youtubeId);
    return isValidYoutubeId(extractedId) && !!title && !loading;
  };

  const handleAddClick = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setShowLoginDialog(true);
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        sx={{ 
          borderRadius: '12px',
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        add
      </Button>

      <LoginRequiredDialog
        open={showLoginDialog}
        onClose={() => setShowLoginDialog(false)}
        redirectPath={location.pathname}
      />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          動画の追加
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="YouTube URL または ID"
            value={youtubeId}
            onChange={(e) => handleYoutubeIdChange(e.target.value)}
            margin="normal"
            helperText="YouTubeのURLまたは動画IDを入力してください"
            error={!!urlError}
          />

          {urlError && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {urlError}
            </Alert>
          )}

          {previewUrl && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Alert severity="warning" sx={{ mb: 2 }}>
                この動画が正しいことを確認してください
              </Alert>
              <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                <iframe
                  src={`https://www.youtube.com/embed/${extractYoutubeId(youtubeId)}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                  }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            </Box>
          )}

          <TextField
            select
            fullWidth
            label="動画タイプ"
            value={type}
            onChange={(e) => setType(Number(e.target.value))}
            margin="normal"
          >
            <MenuItem value={0}>ローンチ、ファイナル</MenuItem>
            <MenuItem value={1}>PV</MenuItem>
            <MenuItem value={2}>ティザー</MenuItem>
            <MenuItem value={3}>ゲームプレイ</MenuItem>
            <MenuItem value={4}>CM</MenuItem>
            <MenuItem value={5}>追加コンテンツ</MenuItem>
            <MenuItem value={8}>インタビュー</MenuItem>
            <MenuItem value={9}>その他</MenuItem>
          </TextField>

          <TextField
            fullWidth
            label="タイトル"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
          />

          <TextField
            fullWidth
            label="説明"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={2}
          />

          <TextField
            type="date"
            fullWidth
            label="公開日"
            value={publishDate}
            onChange={(e) => setPublishDate(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!isFormValid()}
          >
            {loading ? '追加中...' : '追加'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoUploadButton;