import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const RatingContainer = styled(Box)(({ isMobile }: { isMobile?: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: isMobile ? '4px' : '8px',
  padding: isMobile ? '4px' : '12px',
  backgroundColor: isMobile ? 'rgba(255, 255, 255, 0.04)' : 'rgba(255, 255, 255, 0.05)',
  backdropFilter: isMobile ? 'blur(4px)' : 'blur(4px)',
  WebkitBackdropFilter: isMobile ? 'blur(4px)' : 'blur(4px)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  width: isMobile ? '64px' : 'auto',
}));

const ScoreCircle = styled(Box)(({ isMobile }: { isMobile?: boolean }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: isMobile ? '56px' : '90px',
  height: isMobile ? '56px' : '90px',
}));

const CircleBackground = styled(CircularProgress)({
  position: 'absolute',
  color: 'rgba(255, 255, 255, 0.1)',
});

const CircleProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  color: '#4CAF50',
  '& circle': {
    strokeLinecap: 'round',
  },
}));

const ScoreText = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

interface GameRatingProps {
  rating: number | null;
  ratingCount?: number;
  isMobile?: boolean;
}

const GameRating: React.FC<GameRatingProps> = ({ rating, ratingCount = 0, isMobile = false }) => {
  if (!rating || ratingCount < 5) return null;

  const normalizedRating = Math.round((rating / 20) * 10) / 10; // 100点満点を5点満点に変換し、小数点第1位で四捨五入
  const progressValue = (rating);

  // スコアに応じて色を変更
  const getScoreColor = (score: number) => {
    if (score >= 90) return '#4CAF50'; // 緑
    if (score >= 80) return '#8BC34A'; // ライトグリーン
    if (score >= 70) return '#CDDC39'; // ライム
    if (score >= 60) return '#FFEB3B'; // 黄色
    if (score >= 50) return '#FFC107'; // アンバー
    if (score >= 40) return '#FF9800'; // オレンジ
    return '#F44336'; // 赤
  };

  const scoreColor = getScoreColor(rating);

  return (
    <RatingContainer isMobile={isMobile}>
      <Typography variant="caption" sx={{ 
        color: '#a7a7a7',
        fontSize: isMobile ? '0.6rem' : '0.75rem',
        fontWeight: 500,
        letterSpacing: '0.02em'
      }}>
        Score
      </Typography>
      <ScoreCircle isMobile={isMobile}>
        <CircleBackground
          variant="determinate"
          value={100}
          size={isMobile ? 48 : 80}
          thickness={2}
        />
        <CircleProgress
          variant="determinate"
          value={progressValue}
          size={isMobile ? 48 : 80}
          thickness={2}
          sx={{ color: scoreColor }}
        />
        <ScoreText>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontSize: isMobile ? '0.8rem!important' : '1.5rem',
              fontWeight: 'bold',
              color: '#ffffff',
              lineHeight: 1
            }}
          >
            {normalizedRating.toFixed(1)}
          </Typography>
        </ScoreText>
      </ScoreCircle>
    </RatingContainer>
  );
};

export default GameRating; 