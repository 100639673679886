import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, IconButton, LinearProgress, FormControlLabel, Checkbox } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { uploadGameImage } from '../../services/api';

interface ImageUploadButtonProps {
  gameId: number;
  onUploadComplete?: (imageUrls: string[]) => void;
  onError?: (error: string) => void;
}

const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({ gameId, onUploadComplete, onError }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<number>(1);
  const [description, setDescription] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [skipOriginal, setSkipOriginal] = useState(true);
  const [resizeOption, setResizeOption] = useState<number>(1280);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles(prev => [...prev, ...filesArray]);
    }
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) return;

    setLoading(true);
    setUploadProgress(0);
    const uploadedUrls: string[] = [];

    try {
      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append('file', selectedFiles[i]);
        formData.append('game_id', gameId.toString());
        formData.append('type', type.toString());
        formData.append('description', description);
        formData.append('skip_original', skipOriginal.toString());
        formData.append('resize_size', resizeOption.toString());

        const response = await uploadGameImage(formData);
        uploadedUrls.push(response.url);
        setUploadProgress(((i + 1) / selectedFiles.length) * 100);
      }

      if (onUploadComplete) {
        onUploadComplete(uploadedUrls);
      }
      setOpen(false);
    } catch (error) {
      console.error('画像のアップロードに失敗しました', error);
      if (onError) {
        onError('画像のアップロードに失敗しました');
      }
    } finally {
      setLoading(false);
      setSelectedFiles([]);
      setUploadProgress(0);
    }
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<FileUploadIcon />}
        onClick={() => setOpen(true)}
        sx={{ 
          borderRadius: '12px',
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        upload
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          画像のアップロード
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="画像タイプ"
            value={type}
            onChange={(e) => setType(Number(e.target.value))}
            margin="normal"
          >
            <MenuItem value={1}>スクリーンショット</MenuItem>
            <MenuItem value={2}>アートワーク</MenuItem>
            <MenuItem value={3}>パッケージ</MenuItem>
            <MenuItem value={9}>その他</MenuItem>
          </TextField>

          <TextField
            fullWidth
            label="説明"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={2}
          />

          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ mt: 2 }}
          >
            ファイルを選択（複数可）
            <input
              type="file"
              hidden
              accept="image/*"
              multiple
              onChange={handleFileSelect}
            />
          </Button>

          {selectedFiles.length > 0 && (
            <div style={{ marginTop: 16 }}>
              <div>選択されたファイル:</div>
              {selectedFiles.map((file, index) => (
                <div key={index} style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 8 
                }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {file.name}
                  </div>
                  <IconButton size="small" onClick={() => removeFile(index)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}

          {loading && (
            <LinearProgress 
              variant="determinate" 
              value={uploadProgress} 
              sx={{ mt: 2 }}
            />
          )}

          <FormControlLabel 
            control={
              <Checkbox
                checked={!skipOriginal}
                onChange={(e) => setSkipOriginal(!e.target.checked)}
              />
            }
            label="オリジナル画像を保存する"
          />

          {skipOriginal && (
            <TextField
              select
              fullWidth
              label="リサイズオプション"
              value={resizeOption}
              onChange={(e) => setResizeOption(Number(e.target.value))}
              margin="normal"
              helperText="変換後の最大サイズを選択してください"
            >
              <MenuItem value={512}>512px</MenuItem>
              <MenuItem value={768}>768px</MenuItem>
              <MenuItem value={960}>960px</MenuItem>
              <MenuItem value={1280}>1280px</MenuItem>
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <Button
            onClick={handleUpload}
            variant="contained"
            disabled={selectedFiles.length === 0 || loading}
          >
            {loading ? `アップロード中 (${Math.round(uploadProgress)}%)` : 'アップロード'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageUploadButton;