import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, useMediaQuery } from '@mui/material';
import { getGameDescription } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionUploadDialog from './DescriptionUploadDialog';
import Expandable from '../common/Expandable';
import { isSuperuser } from '../../services/auth';

interface GameDescriptionSectionProps {
  gameId: number;
}

interface Description {
  id: number;
  description: string | null;
  story: string | null;
}

// コンテンツの型定義を追加
interface ContentPart {
  type: 'text' | 'image';
  content: string;
}

const GameDescriptionSection: React.FC<GameDescriptionSectionProps> = ({ gameId }) => {
  const isMobile = useMediaQuery('(max-width:899px)');
  const [description, setDescription] = useState<Description | null>(null);
  const [loading, setLoading] = useState(true);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isUserSuperuser, setIsUserSuperuser] = useState(false);
  const [gifLoaded, setGifLoaded] = useState(false);  // GIF読み込み状態を管理

  const fetchDescription = async () => {
    try {
      const response = await getGameDescription(gameId);
      setDescription(response.data);
    } catch (error) {
      console.error('概要の取得に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      fetchDescription();
      const superuser = await isSuperuser();
      setIsUserSuperuser(superuser);
    };
    init();
  }, [gameId]);

  const handleUploadComplete = () => {
    fetchDescription();
    setUploadDialogOpen(false);
  };

  // URLを画像とテキストに分割する関数を修正
  const parseContent = (text: string): ContentPart[] => {
    if (!text) return [];

    const urlRegex = /(https?:\/\/[^\s]+\.(?:jpg|jpeg|png|gif|webp))\s*/gi; // URLの直後のスペースもマッチするように修正
    let lastIndex = 0;
    const result: ContentPart[] = [];
    let match;

    while ((match = urlRegex.exec(text)) !== null) {
      // URLの前のテキストを追加
      if (match.index > lastIndex) {
        result.push({
          type: 'text',
          content: text.slice(lastIndex, match.index)
        });
      }

      // 画像URLを追加
      result.push({
        type: 'image',
        content: match[1] // マッチしたURL全体ではなく、URL部分のみを取得
      });

      lastIndex = urlRegex.lastIndex; // lastIndexの更新方法を修正
    }

    // 残りのテキストを追加
    if (lastIndex < text.length) {
      result.push({
        type: 'text',
        content: text.slice(lastIndex)
      });
    }

    return result;
  };

  // コンテンツをレンダリングする関数を修正
  const renderContent = (text: string) => {
    if (!text) return null;
    
    const parts = parseContent(text);
    let isFirstGif = true;
    
    return parts.map((part, index) => {
      if (part.type === 'image') {
        const isGif = part.content.toLowerCase().endsWith('.gif');
        const shouldLoadLazy = isMobile && isGif && !isFirstGif && !gifLoaded;
        if (isGif) isFirstGif = false;

        return (
          <Box 
            key={index} 
            sx={{ 
              my: 2,
              '& img': {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: 1,
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
              }
            }}
          >
            {shouldLoadLazy ? (
              <Box
                sx={{
                  position: 'relative',
                  aspectRatio: '5/1',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  borderRadius: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                  }
                }}
                onClick={() => {
                  setGifLoaded(true);  // GIF読み込み状態を更新
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7rem',
                    textAlign: 'center',
                    width: '100%',
                    padding: '0 16px'
                  }}
                >
                  GIFアニメーションを読み込む
                </Box>
              </Box>
            ) : (
              <img src={part.content} alt="" loading="lazy" />
            )}
          </Box>
        );
      } else {
        return (
          <Typography 
            key={index}
            variant="body2" 
            component="span"
            sx={{ 
              whiteSpace: 'pre-wrap',
              color: 'text.secondary',
              lineHeight: 1.35,
              display: 'block'
            }}
          >
            {part.content}
          </Typography>
        );
      }
    });
  };

  // GIFが含まれているかチェックする関数を追加
  const containsGif = (text: string | null): boolean => {
    if (!text) return false;
    const gifRegex = /https?:\/\/[^\s]+\.gif\b/i;
    return gifRegex.test(text);
  };

  if (loading) {
    return null;
  }

  if (!description?.description && !description?.story) {
    // 開発環境またはスーパーユーザーの場合に表示
    if (process.env.NODE_ENV !== 'development' && !isUserSuperuser) {
      return null;
    }

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 1 
        }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>概要</Typography>
          <Button
            startIcon={<EditIcon />}
            onClick={() => setUploadDialogOpen(true)}
            size="small"
          >
            追加
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary">
          このゲームの概要はまだ登録されていません。
        </Typography>
        <DescriptionUploadDialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          gameId={gameId}
          onUploadComplete={handleUploadComplete}
          initialDescription={description}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 0, sm: 2 }, mb: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1 
      }}>
        <Typography variant="h6" component="h2" gutterBottom sx={{ mb: 0 }}>概要</Typography>
        {(process.env.NODE_ENV === 'development' || isUserSuperuser) && (
          <IconButton
            size="small"
            onClick={() => setUploadDialogOpen(true)}
            sx={{ color: 'text.secondary' }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Expandable 
        maxHeight={
          containsGif(description?.description) || containsGif(description?.story)
            ? (isMobile ? 320 : 550)  // GIFが含まれている場合は高さを大きくする
            : (isMobile ? 140 : 160)  // GIFがない場合は元の高さ
        } 
        expandButtonStyle="pill" 
        iconPadding={0} 
        pillTextAlign={isMobile ? "center" : "right"}
      >
        {description.description && (
          <Box sx={{ mb: description.story ? 2 : 0 }}>
            {renderContent(description.description)}
          </Box>
        )}
        {description.story && (
          <>
            {description.description && (
              <Typography variant="subtitle2" component="h3" sx={{ mt: 2, mb: 1 }}>ストーリー</Typography>
            )}
            <Box>
              {renderContent(description.story)}
            </Box>
          </>
        )}
      </Expandable>
      <DescriptionUploadDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        gameId={gameId}
        onUploadComplete={handleUploadComplete}
        initialDescription={description}
      />
    </Box>
  );
};

export default GameDescriptionSection;