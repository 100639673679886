import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button, IconButton } from '@mui/material';
import ReviewList from './ReviewList';
import { getGameReviews } from '../../services/api';
import { Review } from '../../types/Review';
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CreateIcon from '@mui/icons-material/Create';
import Dialog from '../dialogs/Dialog';
import LoginRequiredDialog from '../dialogs/LoginRequiredDialog';

interface ReviewSectionProps {
  gameId: number;
  limit?: number;
  onTotalReviewsChange?: (total: number) => void;
  onReviewClick?: () => void;
  isAuthenticated?: boolean;
  gameStatus?: number | null;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ 
  gameId, 
  limit, 
  onTotalReviewsChange,
  onReviewClick,
  isAuthenticated,
  gameStatus
}) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [showStatusError, setShowStatusError] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { results, count } = await getGameReviews(gameId, undefined, limit);
        setReviews(results);
        if (onTotalReviewsChange) {
          onTotalReviewsChange(count);
        }
      } catch (error) {
        console.error('レビューの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [gameId, limit, onTotalReviewsChange]);

  const handleReviewClick = () => {
    if (!isAuthenticated) {
      setShowLoginDialog(true);
      return;
    }

    if (gameStatus && [1, 2, 3].includes(gameStatus)) {
      onReviewClick?.();
    } else {
      setShowStatusError(true);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: { xs: 3, sm: 4 } }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1 
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" component="h2">
            ユーザーレビュー
          </Typography>
          <IconButton
            component={Link}
            to={`/game/${gameId}/reviews`}
            size="small"
            sx={{ 
              ml: 0.5,
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
              }
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
        <Button
          variant="text"
          startIcon={<CreateIcon />}
          onClick={handleReviewClick}
          sx={{ 
            borderRadius: '12px',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          review
        </Button>
      </Box>

      <LoginRequiredDialog
        open={showLoginDialog}
        onClose={() => setShowLoginDialog(false)}
        redirectPath={location.pathname}
      />

      <Dialog
        open={showStatusError}
        onClose={() => setShowStatusError(false)}
        title="レビューを書くには"
        content="レビューを書くには、ゲームのステータスを「プレイ済み」「プレイ中」「中断」のいずれかに設定してください。"
      />
      
      {reviews.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          このゲームのレビューはまだ登録されていません。
        </Typography>
      ) : (
        <>
          <ReviewList reviews={reviews} />
          {reviews.length >= 3 && (
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button
                component={Link}
                to={`/game/${gameId}/reviews`}
                variant="text"
                size="small"
              >
                すべてのレビューを見る
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ReviewSection;