import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Grid, Skeleton, Paper, Button } from '@mui/material';
import { getReleaseCalendar } from '../services/api';
import LightweightGameCard from '../components/LightweightGameCard';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';

const formatMonth = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}年${date.getMonth() + 1}月`;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}月${date.getDate()}日`;
};

const formatFullDate = (dateString: string, currentDate: string) => {
  const date = new Date(`${currentDate}-${dateString.padStart(2, '0')}`);
  return date.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short'
  });
};

interface Game {
  id: number;
  n: string;
  c: string | null;
}

interface GroupedGames {
  [key: string]: {
    [key: string]: Game[];
  };
}

interface CalendarData {
  c: {
    [key: string]: Game[];
  };
  d: string;
  p: string;
  n: string;
}

interface LazyDaySectionProps {
  day: string;
  games: Game[];
  currentDate: string;
  getGameCoverUrl: (imageId: string | null) => string;
}

const ReleaseCalendarPage = () => {
  const [calendarData, setCalendarData] = useState<CalendarData | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const MIN_DATE = '1969-12';
  const MAX_DATE = (() => {
    const date = new Date();
    date.setMonth(date.getMonth() + 24);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  })();

  const isNavigationDisabled = (date: string, direction: 'prev' | 'next') => {
    if (!date) return true;
    if (direction === 'prev' && date <= MIN_DATE) return true;
    if (direction === 'next' && date >= MAX_DATE) return true;
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const date = searchParams.get('date');
        const data = await getReleaseCalendar(date);
        setCalendarData(data);
      } catch (error) {
        console.error('発売カレンダーの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [searchParams]);

  const handleMonthChange = (date: string) => {
    navigate(`/release-calendar?date=${date}`);
  };

  const getGameCoverUrl = (imageId: string | null) => {
    if (!imageId) return '/image/no_image.png';
    return `https://images.igdb.com/igdb/image/upload/t_cover_big/${imageId}.jpg`;
  };

  const getTotalGames = (data: CalendarData) => {
    return Object.values(data.c).reduce((total, games) => total + games.length, 0);
  };

  if (loading) {
    return (
      <Box>
        <Skeleton variant="text" height={40} width="50%" />
        <Skeleton variant="text" height={30} width="30%" />
        <Grid container spacing={1} sx={{ mt: 2 }}>
          {[...Array(12)].map((_, index) => (
            <Grid item xs={4} sm={2.4} md={1.71} key={index}>
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: '1280px', mx: 'auto' }}>
      <Helmet>
        <title>
          {calendarData && `ゲーム発売日カレンダー ${formatMonth(calendarData.d)} スケジュール | PULSE データベース`}
        </title>
      </Helmet>

      <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
          ご利用にあたっての注意事項
        </Typography>
        <Typography variant="body2" component="ul" sx={{ 
          fontSize: '0.7rem',
          pl: 2,
          m: 0,
          '& li': { mb: 0.5 }
        }}>
          <li>本カレンダーは、本サイトに保管されたデータベース情報をもとに自動生成されています。</li>
          <li>情報はリアルタイムで更新されているわけではないため、実際の発売日と異なる場合があります。</li>
          <li>発売時期が未定の場合、四半期末日（Q1→3/31など）や年末（2025年予定→2025/12/31）などで暫定的に設定している場合があります。</li>
          <li>正確な発売日情報は、必ず各タイトルの公式サイトやメーカー発表をご確認ください。</li>
        </Typography>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'stretch', sm: 'center' }, 
        mb: 4,
        borderBottom: '1px solid',
        borderColor: 'divider',
        pb: 2,
        gap: 2
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontSize: { xs: '1.5rem', sm: '2rem' },
              fontWeight: 600,
              mb: 1
            }}
          >
            {calendarData && `${formatMonth(calendarData.d)}の発売スケジュール`}
          </Typography>
          <Typography 
            variant="subtitle1" 
            color="text.secondary"
            sx={{ fontSize: '1rem' }}
          >
            {calendarData && `${formatMonth(calendarData.d)}は計${getTotalGames(calendarData)}本のゲームが${new Date(calendarData.d) > new Date() ? '発売予定です' : '発売されました'}`}
          </Typography>
        </Box>
        <Box sx={{ 
          display: 'flex',
          gap: 1,
          justifyContent: { xs: 'stretch', sm: 'flex-end' },
          '& .MuiButton-root': {
            flex: { xs: 1, sm: 'initial' }
          }
        }}>
          {calendarData && (
            <>
              <Button 
                onClick={() => handleMonthChange(calendarData.p)} 
                startIcon={<ChevronLeft />}
                disabled={isNavigationDisabled(calendarData.p, 'prev')}
                sx={{ 
                  borderRadius: 2,
                  textTransform: 'none'
                }}
                variant="outlined"
              >
                前月
              </Button>
              <Button 
                onClick={() => handleMonthChange(calendarData.n)}
                endIcon={<ChevronRight />}
                disabled={isNavigationDisabled(calendarData.n, 'next')}
                sx={{ 
                  borderRadius: 2,
                  textTransform: 'none'
                }}
                variant="outlined"
              >
                次月
              </Button>
            </>
          )}
        </Box>
      </Box>

      {calendarData && Object.entries(calendarData.c)
        .sort(([dayA], [dayB]) => parseInt(dayA) - parseInt(dayB))
        .map(([day, games]) => (
          <LazyDaySection 
            key={day} 
            day={day} 
            games={games} 
            currentDate={calendarData.d}
            getGameCoverUrl={getGameCoverUrl}
          />
        ))}

      {calendarData && Object.keys(calendarData.c).length === 0 && (
        <Box sx={{ 
          textAlign: 'center',
          py: 8,
          color: 'text.secondary'
        }}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
            発売予定タイトルはありません
          </Typography>
          <Typography variant="body2">
            この月に発売が予定されているゲームは見つかりませんでした。
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const LazyDaySection = ({ day, games, currentDate, getGameCoverUrl }: LazyDaySectionProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '100px 0px'
  });

  return (
    <Box ref={ref} sx={{ mb: 4 }}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        mb: 2,
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.default',
        zIndex: 1,
        py: 0.5,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 500 }}>
          {formatFullDate(day, currentDate)}
        </Typography>
        <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary' }}>
          {games.length}本のゲーム
        </Typography>
      </Box>
      
      {inView && (
        <Grid container spacing={1}>
          {games.map((game: Game) => (
            <Grid item xs={4} sm={2.4} md={1.71} key={game.id}>
              <LightweightGameCard 
                game={{
                  game_id: game.id,
                  name: game.n,
                  cover: getGameCoverUrl(game.c)
                }} 
                openInNewTab={true}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ReleaseCalendarPage; 