import React, { useState, useCallback, useEffect } from 'react';
import { Box, TextField, Select, MenuItem, SelectChangeEvent, Typography, useTheme, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import debounce from 'lodash/debounce';

interface GameListFilterProps {
  onSearch: (query: string) => void;
  onOrderChange: (order: string) => void;
  onClearFilter: () => void;
  totalGames: number;
  initialSearchQuery: string;
  initialOrder: string;
}

const GameListFilter: React.FC<GameListFilterProps> = ({ onSearch, onOrderChange, onClearFilter, totalGames, initialSearchQuery, initialOrder }) => {
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [order, setOrder] = useState(initialOrder);
  const theme = useTheme();

  // デバウンス処理を行う関数を作成
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      onSearch(query);
    }, 1000),
    [onSearch]
  );

  useEffect(() => {
    setSearchQuery(initialSearchQuery);
    setOrder(initialOrder);
  }, [initialSearchQuery, initialOrder]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleOrderChange = (event: SelectChangeEvent) => {
    const newOrder = event.target.value;
    setOrder(newOrder);
    onOrderChange(newOrder);
  };

  const clearFilter = () => {
    setSearchQuery('');
    setOrder('default');
    onClearFilter();
  };

  // 検索クエリが空でない場合のみフィルターがアクティブとみなす
  const isFilterActive = searchQuery !== '';

  // orderの表示テキストをマッピング
  const orderDisplayText: { [key: string]: string } = {
    'default': 'デフォルト',
    'rating': '評価',
    'play_time': 'プレイ時間',
    'play_start_date': 'プレイ開始日'
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <TextField
          placeholder="Filter..."
          variant="standard"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-input': {
              ...theme.typography.body2,
            },
            '& .MuiInputBase-input::placeholder': {
              opacity: 0.5
            }
          }}
        />
        <Select
          value={order}
          onChange={handleOrderChange}
          displayEmpty
          variant="standard"
          sx={{
            minWidth: 90,
            ...theme.typography.body2,
            '& .MuiSelect-select': {
              paddingY: 0.25,
              display: 'flex',
              alignItems: 'center',
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SortIcon sx={{ mr: 0.5, fontSize: 18 }} />
              {orderDisplayText[selected] || '並び替え'}
            </Box>
          )}
        >
          <MenuItem value="default">デフォルト</MenuItem>
          <MenuItem value="rating">評価</MenuItem>
          <MenuItem value="play_time">プレイ時間</MenuItem>
          <MenuItem value="play_start_date">プレイ開始日</MenuItem>
        </Select>
      </Box>
      {isFilterActive && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            {totalGames}件のゲームが見つかりました
          </Typography>
          <Button
            size="small"
            onClick={clearFilter}
            startIcon={<CloseIcon fontSize="small" />}
            sx={{ ...theme.typography.body2, textTransform: 'none', p: '2px 8px' }}
          >
            Clear filter
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default GameListFilter;
