import React from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { Box, IconButton } from '@mui/material';
import { SortableGameItem as SortableGameItemComponent } from '../GameList/SortableGameItem';
import { SortableGame, SortableGameItem } from '../../types/content';
import DeleteIcon from '@mui/icons-material/Delete';

interface SortableGameGridProps {
  items: SortableGame[];
  onReorder: (newItems: SortableGame[]) => void;
  onDelete?: (id: number) => void;
  maxItems?: number;
  gridTemplateColumns?: {
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
}

export const SortableGameGrid: React.FC<SortableGameGridProps> = ({
  items,
  onReorder,
  onDelete,
  maxItems = 5,
  gridTemplateColumns = {
    xs: 'repeat(2, 1fr)',
    sm: `repeat(${maxItems}, 1fr)`
  }
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      
      const newItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
        ...item,
        order: index
      }));
      
      onReorder(newItems);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(item => item.id)}
        strategy={rectSortingStrategy}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns,
            gap: 2,
            width: '100%'
          }}
        >
          {items.map((item) => (
            <Box key={item.id} sx={{ position: 'relative' }}>
              <SortableGameItemComponent
                item={{
                  id: item.id,
                  game_id: item.id,
                  game_name: item.name,
                  game_cover: item.cover_url,
                  order: item.order,
                  game_japanese_name: null,
                  note: '',
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString()
                }}
              />
              {onDelete && (
                <IconButton
                  onClick={() => onDelete(item.id)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    }
                  }}
                  size="small"
                >
                  <DeleteIcon sx={{ fontSize: '1.2rem', color: 'white' }} />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </SortableContext>
    </DndContext>
  );
}; 