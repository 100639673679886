import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiInputBase-input': {
    lineHeight: 1.65,
    fontSize: '0.8rem',
  },
}));

interface StyledTextFieldProps extends Omit<TextFieldProps, 'variant' | 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  multiline?: boolean;
}

const CustomTextField: React.FC<StyledTextFieldProps> = ({
  value,
  onChange,
  minRows = 3,
  maxRows = 8,
  placeholder = '',
  multiline = true,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <StyledTextField
      fullWidth
      multiline={multiline}
      variant="outlined"
      value={value}
      onChange={handleChange}
      minRows={multiline ? minRows : undefined}
      maxRows={multiline ? maxRows : undefined}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default CustomTextField; 