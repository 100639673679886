interface NavigatorWithStandalone extends Navigator {
  standalone?: boolean;
}

export const isPwa = () => {
  // 開発時は強制的にtrueを返す（テスト後は元に戻すこと）
  // if (process.env.NODE_ENV === 'development') {
  //   return true;
  // }
  // ここまで

  return window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as NavigatorWithStandalone).standalone ||
    document.referrer.includes('android-app://');
}; 