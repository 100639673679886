import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Box, TextField, List, ListItem, Typography, CircularProgress, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import api from '../../services/api';

interface SearchResult {
  game_id: number;
  name: string;
  japanese_name: string | null;
  cover_url?: string | null;
  first_release_date?: string | null;
}

interface GameSearchProps {
  onSelect: (game: SearchResult) => void;
  placeholder?: string;
}

export const GameSearch: React.FC<GameSearchProps> = ({ 
  onSelect,
  placeholder = "ゲーム名を入力して検索..."
}) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const LIMIT = 10;

  const searchGames = useCallback(async (
    searchQuery: string, 
    currentOffset: number = 0, 
    append: boolean = false
  ) => {
    if (searchQuery.length < 2) {
      setResults([]);
      setHasMore(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.get('/games/quick_search/', {
        params: { 
          q: searchQuery,
          offset: currentOffset,
          limit: LIMIT
        }
      });
      
      const newResults = response.data || [];
      setHasMore(newResults.length === LIMIT);
      
      if (append) {
        setResults(prev => [...prev, ...newResults]);
      } else {
        setResults(newResults);
      }
    } catch (error) {
      console.error('ゲーム検索に失敗しました:', error);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedSearchRef = useRef<ReturnType<typeof debounce>>();

  useEffect(() => {
    debouncedSearchRef.current = debounce((query: string, offset: number, append: boolean) => {
      searchGames(query, offset, append);
    }, 500);

    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [searchGames]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    
    if (newQuery.length < 2) {
      setResults([]);
      setHasMore(false);
      return;
    }
    
    setOffset(0);
    debouncedSearchRef.current?.(newQuery, 0, false);
  };

  const loadMore = () => {
    if (!isLoading && hasMore) {
      const nextOffset = offset + LIMIT;
      setOffset(nextOffset);
      searchGames(query, nextOffset, true);
    }
  };

  return (
    <Box>
      <TextField
        fullWidth
        value={query}
        onChange={handleSearchChange}
        placeholder={placeholder}
        InputProps={{
          startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
        }}
      />

      {results.length > 0 && (
        <List sx={{ mt: 1, maxHeight: 300, overflowY: 'auto' }}>
          {results.map((game) => (
            <ListItem
              key={game.game_id}
              sx={{
                cursor: 'pointer',
                '&:hover': { bgcolor: 'action.hover' },
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}
              onClick={() => {
                onSelect(game);
                setQuery('');
                setResults([]);
              }}
            >
              {game.cover_url && (
                <Box
                  component="img"
                  src={game.cover_url}
                  alt={game.japanese_name || game.name}
                  sx={{
                    width: 40,
                    height: 'auto',
                    borderRadius: 1
                  }}
                />
              )}
              <Typography>
                {game.japanese_name || game.name}
                {game.first_release_date && (
                  <Typography
                    component="span"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    ({new Date(game.first_release_date).getFullYear()})
                  </Typography>
                )}
              </Typography>
            </ListItem>
          ))}
          
          {hasMore && (
            <ListItem
              sx={{
                justifyContent: 'center',
                py: 1
              }}
            >
              <Button
                onClick={loadMore}
                disabled={isLoading}
                size="small"
                startIcon={isLoading && <CircularProgress size={16} />}
              >
                {isLoading ? '読み込み中...' : 'さらに読み込む'}
              </Button>
            </ListItem>
          )}
        </List>
      )}

      {isLoading && results.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
    </Box>
  );
};