import React from 'react';
import { Box, Container, Typography, Grid, Skeleton, CircularProgress, Paper, Icon, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import GamesIcon from '@mui/icons-material/Games';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Link as RouterLink } from 'react-router-dom';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const featureCardHover = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
`;

const IntroductionSection: React.FC<{ loading?: boolean }> = ({ loading = false }) => {
  const theme = useTheme();

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: { xs: 8, md: 12 } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box
              sx={{
                width: '100%',
                height: { xs: '200px', sm: '400px', md: '580px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 0
              }}
            >
              <CircularProgress size={40} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" width="300px" height={60} sx={{ mx: 'auto', mb: 2 }} />
            <Skeleton variant="text" width="80%" height={24} sx={{ mx: 'auto', mb: 1 }} />
            <Skeleton variant="text" width="80%" height={24} sx={{ mx: 'auto', mb: 1 }} />
            <Skeleton variant="text" width="60%" height={24} sx={{ mx: 'auto' }} />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ 
              background: theme => `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
              borderRadius: '24px',
              p: { xs: 2, md: 6 },
              mb: { xs: 4, md: 12 },
              mt: { xs: 4, md: 12 }
            }}>
              <Grid container spacing={6}>
                {[1, 2].map((item) => (
                  <Grid item xs={12} key={item}>
                    <Paper elevation={0} sx={{
                      background: theme => `rgba(${theme.palette.mode === 'dark' ? '255,255,255,0.03' : '0,0,0,0.02'})`,
                      borderRadius: '16px',
                      p: 4
                    }}>
                      <Skeleton variant="circular" width={40} height={40} sx={{ mb: 2 }} />
                      <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
                      <Skeleton variant="text" width="100%" height={24} sx={{ mb: 1 }} />
                      <Skeleton variant="text" width="90%" height={24} sx={{ mb: 3 }} />
                      <Skeleton variant="rectangular" width="100%" height={180} sx={{ borderRadius: 2 }} />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: { xs: 8, md: 12 } }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              width: '100%',
              aspectRatio: '16/9',
              position: 'relative',
            }}
          >
            <Box
              component="img"
              src="/image/TOP_image1.png"
              alt="PULSEの特徴"
              sx={{
                width: '100%',
                borderRadius: '16px',
                transform: 'translateY(-20px)',
                opacity: 0,
                animation: `${fadeIn} 1s ease-out 0.3s forwards`,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,
            }}
          >
            For game players.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              maxWidth: '860px',
              mx: 'auto',
              px: 1,
              lineHeight: 1.6,
            }}
          >
            PULSEは、全てのゲームプレイヤーのための、ゲーム記録・管理プラットフォームです。
            これまでにプレイしたゲームを集約し、あなただけのゲームライブラリを簡単に作成できます。ゲーマーとしての経歴を余すことなく表現し、コミュニティに共有してみましょう。
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ 
        background: (theme) => `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.grey[900]})`,
        borderRadius: '24px',
        p: { xs: 2, md: 6 },
        mb: { xs: 4, md: 12 },
        mt: { xs: 4, md: 12 },
        position: 'relative',
        overflow: 'hidden'
      }}>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(25, 118, 210, 0.1), transparent 70%)',
          zIndex: 0
        }} />
        
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{
              background: (theme) => `rgba(${theme.palette.mode === 'dark' ? '255,255,255,0.03' : '0,0,0,0.02'})`,
              borderRadius: '16px',
              overflow: 'hidden',
            }}>
              <Grid container direction={{ xs: 'column', md: 'row' }}>
                <Grid item xs={12} md={6} sx={{ p: 4 }}>
                  <Box sx={{ position: 'relative', zIndex: 1 }}>
                    <GamesIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2, opacity: 0.9 }} />
                    <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
                      ゲームライブラリ
                    </Typography>
                    <Typography sx={{ 
                      mb: 3, 
                      color: 'text.secondary',
                      fontSize: { xs: '0.9rem', md: '1rem' },
                      lineHeight: 1.5 
                    }}>
                      全てのゲーム体験を詳細なステータスで保存。作成したライブラリを共有すればゲームプレイヤーとしての経歴が一目で分かります。
                    </Typography>
                    <Box sx={{
                      p: 2,
                      borderRadius: '12px',
                      background: (theme) => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.02)',
                    }}>
                      <Typography component="li" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <Box component="span" sx={{ 
                          width: 6, 
                          height: 6, 
                          borderRadius: '50%', 
                          bgcolor: 'primary.main',
                          mr: 2 
                        }}/>
                        プレイ中・積みゲーなどの5つのステータス管理
                      </Typography>
                      <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="span" sx={{ 
                          width: 6, 
                          height: 6, 
                          borderRadius: '50%', 
                          bgcolor: 'primary.main',
                          mr: 2 
                        }}/>
                        リストやランキングも作成可能
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  p: 2,
                  pb: { xs: 4, md: 2 },
                }}>
                  <Box 
                    component="img"
                    src="/image/TOP_profile.png"
                    sx={{
                      width: '100%',
                      borderRadius: '12px',
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} sx={{
              background: (theme) => `rgba(${theme.palette.mode === 'dark' ? '255,255,255,0.03' : '0,0,0,0.02'})`,
              borderRadius: '16px',
              overflow: 'hidden',
            }}>
              <Grid container direction={{ xs: 'column', md: 'row' }}>
                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} sx={{ p: 4 }}>
                  <Box sx={{ position: 'relative', zIndex: 1 }}>
                    <AssessmentIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2, opacity: 0.9 }} />
                    <Typography variant="h4" sx={{ mb: 3, fontWeight: 600 }}>
                      ログ・スコアリング
                    </Typography>
                    <Typography sx={{ 
                      mb: 3, 
                      color: 'text.secondary',
                      fontSize: { xs: '0.9rem', md: '1rem' },
                      lineHeight: 1.5 
                    }}>
                      あなたならではの視点でゲームを記録・評価できます。プレイした時期や機種、プレイ時間を記録し、ライブラリを充実させよう。
                    </Typography>
                    <Box sx={{
                      p: 2,
                      borderRadius: '12px',
                      background: (theme) => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.02)',
                    }}>
                      <Typography component="li" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                        <Box component="span" sx={{ 
                          width: 6, 
                          height: 6, 
                          borderRadius: '50%', 
                          bgcolor: 'primary.main',
                          mr: 2 
                        }}/>
                        プレイ時間やプレイ時期での並び替え
                      </Typography>
                      <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="span" sx={{ 
                          width: 6, 
                          height: 6, 
                          borderRadius: '50%', 
                          bgcolor: 'primary.main',
                          mr: 2 
                        }}/>
                        総プレイ数や1年のプレイ数の統計
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  p: 2,
                  pb: { xs: 4, md: 2 },
                }}>
                  <Box 
                    component="img"
                    src="/image/TOP_reviewDia.png"
                    sx={{
                      width: '100%',
                      borderRadius: '12px',
                      boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: { xs: 4, md: 6 }, 
          mb: { xs: 2, md: 0 },
          textAlign: 'center',
          position: 'relative',
          zIndex: 1 
        }}>
          <Button
            component={RouterLink}
            to="/login"
            variant="outlined"
            sx={{ 
              borderRadius: '12px',
              px: 2.5,
              py: 1.25,
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
              backdropFilter: 'blur(10px)',
              borderColor: 'rgba(255, 255, 255, 0.1)',
              color: 'text.primary',
              textTransform: 'none',
              fontSize: '0.9rem',
              fontWeight: 500,
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.06)',
                borderColor: 'rgba(255, 255, 255, 0.2)',
                transform: 'translateY(-2px)',
              }
            }}
          >
            今すぐライブラリを開設
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default IntroductionSection; 