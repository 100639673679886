import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { UserProfile } from '../../types/UserProfile';

interface ProfileEditFormProps {
  profile: UserProfile;
  onSave: (updatedProfile: UserProfile) => Promise<void>;
}

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({ profile, onSave }) => {
  const [formData, setFormData] = useState(profile);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [ageWarning, setAgeWarning] = useState<string | null>(null);

  useEffect(() => {
    setFormData(profile);
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'birth_date' && value) {
      const { age } = validateAge(value);
      if (age < 13) {
        setAgeWarning('13歳未満の方は保護者の同意が必要です');
      } else {
        setAgeWarning(null);
      }
    }
  };

  const validateAge = (birthDate: string): { isValid: boolean; age: number } => {
    const today = new Date();
    const birth = new Date(birthDate);
    const age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      return { isValid: false, age: age - 1 };
    }
    return { isValid: true, age };
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    
    if (!formData.birth_date) {
      newErrors.birth_date = '生年月日は必須です';
      setValidationErrors(newErrors);
      return false;
    }

    const { age } = validateAge(formData.birth_date);
    
    if (age < 5) {
      newErrors.birth_date = '5歳以上である必要があります';
    } else if (age >= 100) {
      newErrors.birth_date = '100歳未満である必要があります';
    }
    
    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (formData.birth_date) {
      const { age } = validateAge(formData.birth_date);
      if (age < 13) {
        setAgeWarning('13歳未満の方は保護者の同意が必要です');
      }
    }
  }, [formData.birth_date]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      const profileData = {
        display_name: formData.display_name,
        bio: formData.bio,
        location: formData.location,
        birth_date: formData.birth_date
      };
      
      await onSave(profileData as UserProfile);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const newErrors: { [key: string]: string } = {};
        Object.keys(serverErrors).forEach(key => {
          newErrors[key] = serverErrors[key][0];
        });
        setErrors(newErrors);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 400 }}>
      <TextField
        fullWidth
        margin="normal"
        label="表示名"
        name="display_name"
        value={formData.display_name}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="自己紹介"
        name="bio"
        value={formData.bio}
        onChange={handleChange}
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        margin="normal"
        label="場所"
        name="location"
        value={formData.location}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        required
        margin="normal"
        label="生年月日"
        name="birth_date"
        type="date"
        value={formData.birth_date || ''}
        onChange={handleChange}
        error={Boolean(validationErrors.birth_date)}
        helperText={validationErrors.birth_date || ageWarning}
        FormHelperTextProps={{
          sx: { 
            color: ageWarning ? 'warning.main' : (validationErrors.birth_date ? 'error.main' : 'inherit')
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: new Date(new Date().setFullYear(new Date().getFullYear() - 100)).toISOString().split('T')[0]
        }}
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        保存
      </Button>
      {Object.keys(errors).map((key) => (
        <Typography key={key} color="error" sx={{ mt: 2 }}>
          {errors[key]}
        </Typography>
      ))}
    </Box>
  );
};

export default ProfileEditForm;