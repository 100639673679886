// ActivityTab.tsx
import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, Paper, Divider, Button, Grid, Container } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import api, { getUserActivities } from '../../services/api';
import ShareButtons from '../common/ShareButtons';

interface Activity {
  type: 'status' | 'review';
  game: {
    id: number;
    name: string;
    cover_image: string | null;
  };
  status?: number;
  review_text?: string;
  created_at: string;
  action_type: number;
  old_status?: number;
  new_status?: number;
}

// ステータスの名称マッピングをグローバルに定義
const statusNames: { [key: number]: string } = {
  1: 'プレイ済み',
  2: 'プレイ中',
  3: '中断',
  4: '積みゲー',
  5: '気になる'
};

const getStatusMessage = (actionType: number, oldStatus?: number, newStatus?: number) => {
  // 新規追加の場合
  if (actionType === 1) {
    switch (newStatus) {
      case 1: return 'プレイ済みに追加';
      case 2: return 'プレイを開始';
      case 3: return '中断に追加';
      case 4: return '積みゲーに追加';
      case 5: return 'ウィッシュリストに追加';
      default: return 'ステータスを設定';
    }
  }

  // ステータス変更の場合
  if (actionType === 2) {
    // プレイ中→プレイ済み
    if (oldStatus === 2 && newStatus === 1) {
      return 'プレイを終えた';
    }
    if (oldStatus === 3 && newStatus === 2) {
      return 'プレイを再開';
    }
    if (oldStatus === 2 && newStatus === 3) {
      return 'プレイを中断';
    }
    // その他の変更
    return `${statusNames[oldStatus!]}から${statusNames[newStatus!]}に変更`;
  }

  return '';
};

const formatTimeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  // ミリ秒を各単位に変換
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes < 1) {
    return 'たった今';
  } else if (minutes < 60) {
    return `${minutes}分前`;
  } else if (hours < 24) {
    return `${hours}時間前`;
  } else if (days < 30) {
    return `${days}日前`;
  } else if (months < 12) {
    return `${months}ヶ月前`;
  } else {
    return `${years}年前`;
  }
};

const ActivityItem: React.FC<{ 
  activity: Activity;
  username?: string;
  isPublic?: boolean;
}> = ({ activity, username, isPublic }) => {
  const timeAgo = formatTimeAgo(activity.created_at);
  const statusMessage = activity.type === 'status' 
    ? getStatusMessage(activity.action_type, activity.old_status, activity.new_status)
    : activity.action_type === 3 
      ? 'レビューを投稿' 
      : activity.action_type === 4 
        ? 'レビューを更新' 
        : '';

  // シェア用のメッセージを生成
  const SHARE_SUFFIX = ' | PULSE - ゲーム記録管理';
  const getShareMessage = () => {
    let message = '';
    if (activity.type === 'status') {
      if (activity.action_type === 1) {
        // 新規追加の場合
        switch (activity.new_status) {
          case 1:
            message = `『${activity.game.name}』をプレイ済みに追加しました${SHARE_SUFFIX}`;
            break;
          case 2:
            message = `『${activity.game.name}』のプレイを開始しました${SHARE_SUFFIX}`;
            break;
          case 3:
            message = `『${activity.game.name}』を中断に追加しました${SHARE_SUFFIX}`;
            break;
          case 4:
            message = `『${activity.game.name}』を積みゲーに追加しました${SHARE_SUFFIX} `;
            break;
          case 5:
            message = `『${activity.game.name}』をウィッシュリストに追加しました${SHARE_SUFFIX}`;
            break;
          default:
            message = `『${activity.game.name}』のステータスを設定しました${SHARE_SUFFIX} `;
        }
      } else if (activity.action_type === 2) {
        // ステータス変更の場合
        if (activity.old_status === 2 && activity.new_status === 1) {
          message = `『${activity.game.name}』のプレイを終えました${SHARE_SUFFIX}`;
        } else if (activity.old_status === 3 && activity.new_status === 2) {
          message = `『${activity.game.name}』のプレイを再開しました${SHARE_SUFFIX}`;
        } else {
          message = `『${activity.game.name}』のステータスを${statusNames[activity.old_status!]}から${statusNames[activity.new_status!]}に変更しました${SHARE_SUFFIX}`;
        }
      }
    } else {
      message = `『${activity.game.name}』のレビューを${activity.action_type === 3 ? '投稿' : '更新'}しました${SHARE_SUFFIX}`;
    }
    return message;
  };

  return (
    <>
      <ListItem 
        sx={{ 
          py: 2,
          '&:hover': {
            backgroundColor: 'action.hover',
          }
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          {/* 左側: アバター */}
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mr: 2 }}>
            <Avatar
              variant="rounded"
              src={activity.game.cover_image 
                ? `https://images.igdb.com/igdb/image/upload/t_cover_small/${activity.game.cover_image}.jpg`
                : '/image/no_image.png'
              }
              sx={{ width: 48, height: 64 }}
            />
          </Box>

          {/* 右側: コンテンツ */}
          <Box sx={{ flex: 1 }}>
            <Box sx={{ 
              mb: 0.5, 
              display: 'flex',  // Flexboxを追加
              justifyContent: 'space-between',  // 両端に配置
              alignItems: 'center'  // 縦方向の中央揃え
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography 
                  component="span" 
                  variant="body2" 
                  color="text.primary"
                  sx={{ fontWeight: 500 }}
                >
                  {statusMessage}
                </Typography>
                <Typography 
                  component="span" 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ 
                    ml: 1,
                    mr: 1,
                    whiteSpace: 'nowrap'
                  }}
                >
                  {timeAgo}
                </Typography>
              </Box>

              {!isPublic && (
                <ShareButtons
                  text={getShareMessage()}
                  url={username ? `/profile/${username}?tab=2` : '/profile?tab=2'}
                  hashtags={['PULSE_DB']}
                  iconSize={16}
                  matchIconColor={true}
                  iconOnly={{ xs: true, md: false }}
                  buttonText="共有"
                />
              )}
            </Box>

            <Link 
              to={`/game/${activity.game.id}`} 
              style={{ textDecoration: 'none' }}
            >
              <Typography 
                variant="body2" 
                color="primary"
                sx={{ 
                  mb: 1,
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {activity.game.name}
              </Typography>
            </Link>

            {activity.type === 'review' && activity.review_text && (
              <Paper 
                variant="outlined" 
                sx={{ 
                  p: 2, 
                  backgroundColor: 'background.paper',
                  borderColor: 'divider'
                }}
              >
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {activity.review_text}
                </Typography>
              </Paper>
            )}
          </Box>
        </Box>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

const ActivityTab: React.FC<{ username?: string, isPublic?: boolean }> = ({ username, isPublic }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState<number | null>(null);

  // URLから現在のページを取得（デフォルトは1）
  const page = parseInt(searchParams.get('page') || '1');
  // メインタブの値を取得
  const tab = searchParams.get('tab') || '0';

  const fetchActivities = useCallback(async (pageNum: number) => {
    // 既に同じページのデータを取得中の場合は中断
    if (currentPage === pageNum) return;
    
    // パブリックモードでusernameが無い場合は中断
    if (isPublic && !username) return;
    
    setCurrentPage(pageNum);
    setLoading(true);
    
    try {
      if (isPublic && username) {
        const response = await getUserActivities(username, pageNum);
        setActivities(response.data.results);
        setHasNext(response.data.next);
      } else if (!isPublic) {
        const response = await getUserActivities(undefined, pageNum);
        setActivities(response.data.results);
        setHasNext(response.data.next);
      }
    } catch (error) {
      console.error('アクティビティの取得に失敗しました', error);
    } finally {
      setLoading(false);
    }
  }, [isPublic, username, currentPage]);

  // ページ変更時のみデータを取得
  useEffect(() => {
    fetchActivities(page);
  }, [page, fetchActivities, username]);

  const handlePageChange = (newPage: number) => {
    setLoading(true);
    // メインタブの値を維持しながらページを更新
    setSearchParams({ page: newPage.toString(), tab: tab });
  };

  if (loading && page === 1) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="text.secondary">読み込み中...</Typography>
      </Box>
    );
  }

  return (
      <Grid container>
        <Grid item xs={12} md={8}>
          <Paper variant="outlined" sx={{ borderRadius: 1, overflow: 'hidden' }}>
            {activities.length === 0 ? (
              <Box sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="body1" color="text.secondary">
                  まだアクティビティはありません
                </Typography>
              </Box>
            ) : (
              <>
                <List disablePadding>
                  {activities.map((activity, index) => (
                    <ActivityItem 
                      key={`${activity.type}-${activity.game.id}-${index}`} 
                      activity={activity}
                      username={username}
                      isPublic={isPublic}
                    />
                  ))}
                </List>
                <Box 
                  sx={{ 
                    p: 2, 
                    display: 'flex', 
                    justifyContent: 'center',
                    gap: 1
                  }}
                >
                  <Button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={loading || page === 1}
                    variant="outlined"
                    size="small"
                  >
                    前のページ
                  </Button>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      px: 2 
                    }}
                  >
                    {page}ページ目
                  </Typography>
                  <Button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={loading || !hasNext}
                    variant="outlined"
                    size="small"
                  >
                    次のページ
                  </Button>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
  );
};

export default ActivityTab;