import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, useTheme, useMediaQuery, CircularProgress, LinearProgress, IconButton } from '@mui/material';
import { SportsEsports, Timer, CalendarToday, Grade, RateReview, Launch } from '@mui/icons-material';
import { UserProfile } from '../types/UserProfile';
import { getProfileStats } from '../services/api';
import Tips from './dialogs/Tips';
import { Link } from 'react-router-dom';
import FavoriteGamesWidget from './profile/FavoriteGamesWidget';

interface ProfileStats {
  total_played: number;
  played_in_current_year: number;
  last_updated?: string;
  game_debut: {
    year: number;
    date: string;
    age?: number;
    age_text?: string;
    game: {
      id: number;
      name: string;
      cover_url: string | null;
    };
  } | null;
  rating_stats: {
    average: number | null;
    count: number;
    distribution: { [key: number]: number };
    last_updated?: string;
  };
  review_count: number;
}

interface ProfileTabProps {
  profile: UserProfile | null;
  isPublic?: boolean;
}

// 共通のPaperスタイルを定義
const commonPaperStyle = {
  p: 2.5,
  height: '100%',
  borderRadius: 2,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  background: 'background.paper',
  backdropFilter: 'blur(10px)',
  transition: 'all 0.2s ease',
  position: 'relative',
};

// アイコンの共通スタイル
const iconStyle = {
  mr: 1,
  color: 'text.primary',
  fontSize: '1rem',
  display: 'none',
};

// 数値表示用のタイポグラフィスタイル
const statsNumberStyle = {
  fontSize: { xs: '1.75rem', sm: '2rem' },
  fontWeight: 600,
  color: '#e6edf3',
  letterSpacing: '-0.5px',
  lineHeight: 1.2
};

// サブテキスト用のスタイル
const subtitleStyle = {
  color: '#8b949e',
};

// LinearProgressのスタイルを更新
const progressStyle = {
  height: 2,
  borderRadius: 2,
  backgroundColor: 'rgba(110, 118, 129, 0.1)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'primary.main'
  }
};

// 相対時刻を計算する関数
const getRelativeTimeString = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return '数秒前';
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes}分前`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours}時間前`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days}日前`;
  }
};

const ProfileTab: React.FC<ProfileTabProps> = ({ profile, isPublic }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [stats, setStats] = useState<ProfileStats | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      if (!profile) return;
      
      try {
        const response = await getProfileStats(isPublic ? profile.username : undefined);
        setStats(response.data);
      } catch (error) {
        console.error('統計情報の取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [profile, isPublic]);

  const statsInfo = !isPublic && (
    <Typography variant="body2">
      <Typography component="p" paragraph>
        プロフィール統計情報の計算方法：
      </Typography>
      <Typography component="p" paragraph>
        <strong>総プレイゲーム数：</strong><br />
        「プレイ済み」「プレイ中」「中断」のステータスが設定されているゲームの合計数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>2024年のプレイ数：</strong><br />
        2024年にプレイを開始したゲーム、または2024年にプレイを終了したゲームの合計数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>評価統計：</strong><br />
        スコアを設定したゲームの平均評価点と評価数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>レビュー投稿数：</strong><br />
        テキストレビューを投稿したゲームの数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>ゲームデビュー：</strong><br />
        登録されているゲームの中で最も古いプレイ開始日を基準に算出しています。
        誕生日が登録されている場合は、その時の年齢も表示されます。
      </Typography>
    </Typography>
  );

  if (!profile) {
    return null;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <FavoriteGamesWidget username={isPublic ? profile.username : undefined} />
      <Box sx={{ py: 2 }}>
        {!isPublic && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" component="h2">
            統計情報
          </Typography>
          
            <Tips 
              title="統計情報について" 
              content={statsInfo}
            />
          </Box>
        )}
        <Grid container spacing={2}>
          {/* 総プレイゲーム数 */}
          <Grid item xs={6} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <SportsEsports sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  総プレイ数
                </Typography>
              </Box>
              <Typography variant="h3" component="div" sx={statsNumberStyle}>
                {stats?.total_played || 0}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={subtitleStyle}>
                games
              </Typography>
              
              <IconButton
                onClick={() => {
                  const newUrl = isPublic 
                    ? `/profile/${profile?.username}?tab=library&status=1` 
                    : '/profile?tab=library&status=1';
                  window.location.href = newUrl;
                }}
                size="small"
                sx={{
                  position: 'absolute',
                  right: 8,
                  bottom: 8,
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
              >
                <Launch fontSize="small" />
              </IconButton>
            </Paper>
          </Grid>

          {/* 現在の年のプレイ数 */}
          <Grid item xs={6} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Timer sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  {new Date().getFullYear()}年プレイ数
                </Typography>
              </Box>
              <Typography variant="h3" component="div" sx={statsNumberStyle}>
                {stats?.played_in_current_year || 0}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={subtitleStyle}>
                games
              </Typography>
            </Paper>
          </Grid>

                  {/* レビュー投稿数 */}
                  <Grid item xs={6} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <RateReview sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  レビュー投稿数
                </Typography>
              </Box>
              <Typography variant="h3" component="div" sx={statsNumberStyle}>
                {stats?.review_count || 0}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={subtitleStyle}>
                reviews written
              </Typography>
            </Paper>
          </Grid>

          {/* 評価統計 */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Grade sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  評価統計
                </Typography>
              </Box>
              <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                {stats?.rating_stats.average ? 
                  stats.rating_stats.average.toFixed(1) : 
                  '-'}
              </Typography>
              <Box sx={{ mb: 1 }}>
                {stats?.rating_stats.distribution && 
                  Object.entries(stats.rating_stats.distribution)
                    .sort(([a], [b]) => Number(b) - Number(a))
                    .map(([score, count]) => {
                      const percentage = (count / stats.rating_stats.count) * 100;
                      return (
                        <Box key={score} sx={{ mb: 0.25 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0 }}>
                            <Typography variant="caption">{Number(score).toFixed(1)}</Typography>
                            <Typography variant="caption">{count}</Typography>
                          </Box>
                          <LinearProgress 
                            variant="determinate" 
                            value={percentage}
                            sx={progressStyle}
                          />
                        </Box>
                      );
                    })}
              </Box>
              <Typography variant="body2" color="text.secondary" sx={subtitleStyle}>
                {`${stats?.rating_stats.count || 0} games rated`}
              </Typography>
              {!isPublic && stats?.rating_stats.last_updated && (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ 
                    display: 'block',
                    textAlign: 'right',
                    fontSize: '0.6rem',
                    fontWeight: '400'
                  }}
                >
                  データ更新: {getRelativeTimeString(stats.rating_stats.last_updated)}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* ゲームデビュー */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarToday sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  ゲームデビュー
                </Typography>
              </Box>
              {stats?.game_debut && (
                <>
                  <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>
                    {stats.game_debut.year}
                    <Typography 
                      component="span" 
                      sx={{ 
                        fontSize: '0.8rem', 
                        color: 'text.primary',
                        ml: 1 
                      }}
                    >
                      /{new Date().getFullYear() - stats.game_debut.year}年前
                    </Typography>
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    sx={subtitleStyle}
                    component={Link}
                    to={`/game/${stats.game_debut.game.id}`}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {stats.game_debut.age_text ? 
                      `${stats.game_debut.age_text}で${stats.game_debut.game.name}を初プレイ` :
                      stats.game_debut.age ? 
                        `${stats.game_debut.age}歳で${stats.game_debut.game.name}を初プレイ` : 
                        stats.game_debut.game.name}
                  </Typography>
                  {stats.game_debut.game.cover_url && (
                    <Box 
                      component={Link}
                      to={`/game/${stats.game_debut.game.id}`}
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        backgroundColor: 'rgba(255, 255, 255, 0.02)',
                        mt: 2,
                        pt: 2,
                        pb: 2,
                        textDecoration: 'none'
                      }}
                    >
                      <Box 
                        component="img"
                        src={stats.game_debut.game.cover_url}
                        alt={stats.game_debut.game.name}
                        sx={{ 
                          width: '100%',
                          maxWidth: 160,
                          height: 'auto',
                          borderRadius: 1,
                          objectFit: 'cover',
                          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.03)'
                          }
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Paper>
          </Grid>

          {isPublic && stats?.last_updated && (
          <Grid item xs={12} >
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.6rem',
              fontWeight: '400'
            }}
          >
            データ更新: {getRelativeTimeString(stats.last_updated)}
          </Typography>
          </Grid>
        )}

        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileTab; 