import React from 'react';
import { Box, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import PublicIcon from '@mui/icons-material/Public';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CodeIcon from '@mui/icons-material/Code';
import { Link } from 'react-router-dom';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface GameMode {
  game_mode_id: number;
  name: string;
}

interface GameEngine {
  game_engine_id: number;
  name: string;
}

interface Keyword {
  keyword_id: number;
  name: string;
  japanese_name: string;
}

interface GameModeIconsProps {
  gameModes: GameMode[];
  gameEngines?: GameEngine[];
  keywords?: Keyword[];
}

const GameModeIcons: React.FC<GameModeIconsProps> = ({ gameModes, gameEngines, keywords }) => {
  const getIconForGameMode = (mode: GameMode) => {
    switch (mode.game_mode_id) {
      case 1: // シングル
        return { icon: <PersonIcon />, color: '#4CAF50' };
      case 2: // マルチ
        return { icon: <GroupsIcon />, color: '#2196F3' };
      case 3: // 協力
        return { icon: <HandshakeIcon />, color: '#FF9800' };
      case 4: // 画面分割
        return { icon: <ScreenShareIcon />, color: '#9C27B0' };
      case 5: // MMO
        return { icon: <PublicIcon />, color: '#E91E63' };
      case 6: // バトルロイヤル
        return { icon: <SportsEsportsIcon />, color: '#F44336' };
      default:
        return null;
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      gap: 1,
      mb: 1,
      flexWrap: 'wrap',
      '& .MuiSvgIcon-root': {
        fontSize: '1rem'
      }
    }}>
      {gameModes.map((mode) => {
        const iconData = getIconForGameMode(mode);
        if (!iconData) return null;

        return (
            <Box sx={{ 
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
              color: iconData.color,
              bgcolor: `${iconData.color}25`,
              padding: '0px 8px',
              borderRadius: '6px',
              fontSize: { xs: '0.6rem', sm: '0.7rem' },
              lineHeight: 1,
              opacity: 0.85,
              transition: 'all 0.2s ease',
              '&:hover': {
                opacity: 1,
                bgcolor: `${iconData.color}25`,
              }
            }}>
              <span style={{ color: 'White' }}>
                {iconData.icon}
              </span>
              <span style={{ color: 'White' }}>
                {mode.name}
              </span>
            </Box>
        );
      })}
      
      {gameEngines && gameEngines.map((engine) => (
        <Box
          key={engine.game_engine_id}
          component={Link}
          to={`/games/game-engine/${engine.game_engine_id}`}
          sx={{ 
            display: 'inline-flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#607D8B',
            bgcolor: 'rgba(96, 125, 139, 0.15)',
            padding: '0px 8px',
            borderRadius: '6px',
            fontSize: { xs: '0.6rem', sm: '0.7rem' },
            lineHeight: 1,
            opacity: 0.85,
            transition: 'all 0.2s ease',
            textDecoration: 'none',
            '&:hover': {
              opacity: 1,
            }
          }}
        >
          <CodeIcon />
          <span style={{ color: 'White' }}>
            {engine.name}
          </span>
        </Box>
      ))}

      {keywords && keywords.map((keyword) => (
        <Box
          key={keyword.keyword_id}
          component={Link}
          to={`/games/keyword/${keyword.keyword_id}`}
          sx={{ 
            display: 'inline-flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#3c5866',
            bgcolor: 'rgba(255, 255, 255, 0.08)',
            padding: '0px 8px',
            borderRadius: '6px',
            fontSize: { xs: '0.6rem', sm: '0.7rem' },
            lineHeight: 1,
            opacity: 0.85,
            transition: 'all 0.2s ease',
            textDecoration: 'none',
            '&:hover': {
              opacity: 1,
            }
          }}
        >
          <LocalOfferIcon />
          <span style={{ color: 'White' }}>
            {keyword.japanese_name || keyword.name}
          </span>
        </Box>
      ))}
    </Box>
  );
};

export default GameModeIcons; 