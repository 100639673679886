import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, ImageList, ImageListItem, Container, Breadcrumbs, useTheme, useMediaQuery, Modal, Button } from '@mui/material';
import { getGameImages, getGameDetails } from '../services/api';
import { Helmet } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface Image {
  id: number;
  image_id: string;
  type: number;
  description: string | null;
  created_at: string;
  original_format: number;
  is_igdb?: boolean;
}

interface SelectedImage extends Image {
  url: string;
  original_url: string | null;
}

const GameImagesPage: React.FC = () => {
  const { game_id } = useParams<{ game_id: string }>();
  const [images, setImages] = useState<Image[]>([]);
  const [gameTitle, setGameTitle] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<SelectedImage | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getImageUrl = (image: Image) => {
    if (image.is_igdb) {
      return `https://images.igdb.com/igdb/image/upload/t_720p/${image.image_id}.jpg`;
    }

    // カスタム画像の場合
    const basePath = `${process.env.REACT_APP_R2_PUBLIC_URL}/images/games/${game_id}`;
    const webpPath = `${image.image_id}_opt.webp`;
    return `${basePath}/${webpPath}`;
  };

  const getOriginalUrl = (image: Image) => {
    if (image.is_igdb) {
      return null;  // IGDBの画像にはオリジナル画像のURLは不要
    }

    if (image.original_format > 0) {
      const basePath = `${process.env.REACT_APP_R2_PUBLIC_URL}/images/games/${game_id}`;
      const formatExtension = {1: 'jpg', 2: 'png', 3: 'gif', 4: 'webp'}[image.original_format];
      return `${basePath}/${image.image_id}.${formatExtension}`;
    }

    return null;
  };

  const handleImageClick = (image: Image) => {
    const selectedImage: SelectedImage = {
      ...image,
      url: getImageUrl(image),
      original_url: getOriginalUrl(image)
    };
    setSelectedImage(selectedImage);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleViewOriginal = (e: React.MouseEvent, url: string) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (game_id) {
          const [imagesResponse, gameDetails] = await Promise.all([
            getGameImages(parseInt(game_id)),
            getGameDetails(parseInt(game_id))
          ]);
          
          setImages(imagesResponse.results || imagesResponse || []);
          setGameTitle(gameDetails.japanese_name || gameDetails.name);
        }
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      }
    };

    fetchData();
  }, [game_id]);

  return (
    <>
      <Helmet>
        <title>{`${gameTitle} の画像一覧 | PULSE`}</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Breadcrumbs 
          separator={<NavigateNextIcon fontSize="small" />} 
          sx={{ mb: 3 }}
        >
          <Link to={`/game/${game_id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
            {gameTitle}
          </Link>
          <Typography color="text.primary">画像一覧</Typography>
        </Breadcrumbs>

        <Typography variant="h4" component="h1" gutterBottom>
          画像一覧
        </Typography>

        {images && images.length > 0 ? (
          <ImageList 
            variant="quilted"
            cols={isMobile ? 1 : 2}
            gap={16}
            sx={{ 
              mb: 0,
              overflow: 'visible',
              '& .MuiImageListItem-root': {
                overflow: 'hidden',
                borderRadius: 1,
                '& img': {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  transform: 'none',
                  position: 'static',
                }
              }
            }}
          >
            {images.map((image) => (
              <ImageListItem 
                key={image.id}
                onClick={() => handleImageClick(image)}
                sx={{
                  maxHeight: '400px',
                  '& img': {
                    borderRadius: 1,
                  },
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                    transition: 'opacity 0.2s',
                  },
                }}
              >
                <img
                  src={getImageUrl(image)}
                  alt={image.description || ''}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Typography>画像がありません</Typography>
        )}

        {selectedImage && (
          <Modal
            open={!!selectedImage}
            onClose={handleCloseModal}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.7)',
            }}
          >
            <Box 
              sx={{ 
                position: 'relative',
                maxWidth: '90vw',
                maxHeight: '90vh',
                width: 'auto',
                height: 'auto',
              }}
              onClick={handleCloseModal}
            >
              <Box
                component="img"
                src={selectedImage.url}
                alt={selectedImage.description || ''}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '90vh',
                  width: 'auto',
                  height: 'auto',
                  minWidth: '300px',
                  minHeight: '300px',
                  objectFit: 'contain',
                  outline: 'none',
                  userSelect: 'none',
                  borderRadius: 2,
                }}
              />
              {selectedImage.original_url && selectedImage?.original_format && selectedImage.original_format > 0 && (
                <Button
                  onClick={(e) => handleViewOriginal(e, selectedImage.original_url!)}
                  sx={{
                    position: 'absolute',
                    bottom: -24,
                    right: 0,
                    color: 'white',
                    fontSize: '0.6rem', 
                    textTransform: 'none',
                    padding: '0 1px',
                    minWidth: 'unset',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      opacity: 0.8,
                    },
                  }}
                >
                  View original
                </Button>
              )}
            </Box>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default GameImagesPage;