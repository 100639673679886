import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Box, Button, Grid, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getGameTriviaDetail } from '../../../services/contents_api';
import { getGameDetails } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { isAuthenticated } from '../../../services/auth';
import LoginRequiredDialog from '../../../components/dialogs/LoginRequiredDialog';
import ShareButtons from '../../../components/common/ShareButtons';
import { useTheme } from '@mui/material/styles';
import { Game } from '../../../types/Game';
import { Helmet } from 'react-helmet-async';
import RectangleAdvertisement from '../../../components/advertisement/Ad_rectangle_300';
import AdvertisementResponsive from '../../../components/advertisement/Ad_GA_res';

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const GameInfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
}));

const CoverImage = styled('img')({
  width: 120,
  height: 170,
  objectFit: 'cover',
  borderRadius: 8,
});

const GameTriviaDetailPage: React.FC = () => {
  const { triviaId } = useParams<{ triviaId: string }>();
  const navigate = useNavigate();
  const [trivia, setTrivia] = useState<any>(null);
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!triviaId) throw new Error('トリビアIDが指定されていません');
        const data = await getGameTriviaDetail(Number(triviaId));
        setTrivia(data);
        
        // トリビアデータを取得後にゲーム情報を取得
        const gameData = await getGameDetails(data.game_id);
        setGame(gameData);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [triviaId]);

  const handleEditClick = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setShowLoginDialog(true);
      return;
    }
    navigate(`/trivia/${triviaId}/edit`);
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography>読み込み中...</Typography>
      </Container>
    );
  }

  if (!trivia || !game) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography color="error">データが見つかりませんでした。</Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${trivia.title}｜『${game.japanese_name || game.name}』の雑学・豆知識｜PULSE`}</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ContentPaper elevation={0}>
              <Box sx={{ 
                mb: 3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}>
                <Typography variant="body1" component="div" gutterBottom sx={{ color: 'text.secondary' }}>
                  『{game.japanese_name || game.name}』の雑学・豆知識
                </Typography>
                <Button
                  onClick={handleEditClick}
                  startIcon={<EditIcon />}
                  size="small"
                  sx={{ color: 'text.secondary' }}
                >
                  編集
                </Button>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <LightbulbIcon sx={{ color: 'text.secondary' }} />
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" component="h1" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                    {trivia.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ 
                    fontSize: '0.8rem', 
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'  // 長い英単語の改行対応
                  }}>
                    {trivia.content}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ 
                mt: 3,
                pt: 2,
                borderTop: '1px solid',
                borderColor: 'divider'
              }}>
                {/* ハッシュタグ部分 */}
                <Box sx={{ 
                  display: 'flex',
                  flexWrap: 'wrap',
                  lineHeight: 1,
                  gap: '0px 8px',
                  mb: 2
                }}>
                  {game.companies?.filter(c => c.role === '発売').map((company) => (
                    <Link 
                      key={company.company_id}
                      to={`/games/company/${company.company_id}`}
                      style={{ textDecoration: 'none', lineHeight: 1 }}
                    >
                      <Typography 
                        component="span" 
                        variant="body2"
                        sx={{ 
                          color: 'secondary.main',
                          '&:hover': {
                            color: 'primary.main'
                          }
                        }}
                      >
                        #{company.japanese_name || company.name}
                      </Typography>
                    </Link>
                  ))}
                  {game.franchises?.map((franchise) => (
                    <Link 
                      key={franchise.franchise_id}
                      to={`/games/franchise/${franchise.franchise_id}`}
                      style={{ textDecoration: 'none', lineHeight: 1 }}
                    >
                      <Typography 
                        component="span" 
                        variant="body2"
                        sx={{ 
                          color: 'secondary.main',
                          '&:hover': {
                            color: 'primary.main'
                          }
                        }}
                      >
                        #{franchise.japanese_name || franchise.name}
                      </Typography>
                    </Link>
                  ))}
                </Box>

                {/* 出典と共有ボタン */}
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}>
                  {trivia.source && (
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{
                        wordBreak: 'break-word',  // 長い英単語も改行
                        maxWidth: '100%'  // 幅いっぱいまで使用可能
                      }}
                    >
                      出典: {trivia.source}
                    </Typography>
                  )}
                  <Box>
                    <ShareButtons
                      text={`${trivia.title}\n\n『${game.japanese_name || game.name}』の豆知識`}
                      url={`/trivia/${triviaId}`}
                      hashtags={['PULSE_DB']}
                    />
                  </Box>
                </Box>
              </Box>
            </ContentPaper>

            <GameInfoPaper elevation={0}>
              {game.cover_hd && (
                <CoverImage
                  src={game.cover_hd}
                  alt={game.japanese_name || game.name}
                />
              )}
              <Box>
                <Link 
                  to={`/game/${game.game_id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography variant="h6" component="h2" gutterBottom>
                    {game.japanese_name || game.name}
                  </Typography>
                </Link>
                
                {game.first_release_date && (
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    発売日: {new Date(game.first_release_date).getFullYear()}年
                  </Typography>
                )}
                
                {game.companies && game.companies.length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    {Object.entries(
                      game.companies.reduce((acc: { [key: string]: string[] }, company) => {
                        if (company.role) {
                          if (!acc[company.role]) {
                            acc[company.role] = [];
                          }
                          acc[company.role].push(company.japanese_name || company.name);
                        }
                        return acc;
                      }, {})
                    ).map(([role, companies], index) => (
                      <Typography key={index} variant="body2" color="text.secondary">
                        {role}: {companies.join('、')}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Box>
            </GameInfoPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            {isMobile ? <RectangleAdvertisement /> : <AdvertisementResponsive />}
          </Grid>
        </Grid>
      </Container>

      <LoginRequiredDialog
        open={showLoginDialog}
        onClose={() => setShowLoginDialog(false)}
        redirectPath={`/trivia/${triviaId}/edit`}
      />
    </>
  );
};

export default GameTriviaDetailPage; 