import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleDiscordCallback, checkProfileCompletion } from '../../services/api';
import { CircularProgress, Box } from '@mui/material';
import Dialog from '../dialogs/Dialog';

const DiscordCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      
      if (code) {
        try {
          const data = await handleDiscordCallback(code);
          
          // トークンを保存
          localStorage.setItem('token', data.access_token);
          localStorage.setItem('refreshToken', data.refresh_token);
          
          // ユーザー情報を保存
          localStorage.setItem('user', JSON.stringify(data.user));
          
          // カスタムイベントを発火してヘッダーの状態を更新
          const event = new CustomEvent('loginSuccess', { detail: data.user });
          window.dispatchEvent(event);
          
          // プロフィールの完成度をチェック
          const isProfileComplete = await checkProfileCompletion();
          if (!isProfileComplete) {
            navigate('/profile/settings');
          } else {
            navigate('/profile');
          }
        } catch (error: any) {
          console.error('Discord callback failed:', error);
          setError(error.response?.data?.error || 'ログインに失敗しました');
        }
      } else {
        setError('認証コードが見つかりません');
      }
    };

    handleCallback();
  }, [navigate, location]);

  const handleCloseError = () => {
    setError(null);
    navigate('/login');
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
      
      <Dialog
        open={!!error}
        onClose={handleCloseError}
        title="エラー"
        content={error || ''}
      />
    </>
  );
};

export default DiscordCallback; 