import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Chip, Skeleton } from '@mui/material';
import { getGamePhrases, PhraseList } from '../../services/contents_api';
import { styled } from '@mui/material/styles';
import PhraseUploadButton from './PhraseUploadButton';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useNavigate } from 'react-router-dom';

interface GamePhraseSectionProps {
  gameId: number;
}

const PhraseCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  }
}));

const SourceChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  fontSize: '0.7rem',
  height: '24px',
}));

const GamePhraseSection: React.FC<GamePhraseSectionProps> = ({ gameId }) => {
  const [phrases, setPhrases] = useState<PhraseList[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchPhrases = async () => {
    try {
      const response = await getGamePhrases(gameId);
      setPhrases(response);
    } catch (error) {
      console.error('キャッチコピーの取得に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhrases();
  }, [gameId]);

  const handlePhraseClick = (phraseId: number) => {
    navigate(`/phrase/${phraseId}`);
  };

  if (loading) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>キャッチコピー</Typography>
        <Grid container spacing={2}>
          {[1, 2].map((i) => (
            <Grid item xs={12} key={i}>
              <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (phrases.length === 0) {
    return (
      <Box sx={{ mt: { xs: 3, sm: 4 } }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 1 
        }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>キャッチコピー</Typography>
          <PhraseUploadButton 
            gameId={gameId}
            onUploadComplete={fetchPhrases}
            onError={(error) => console.error(error)}
          />
        </Box>
        <Typography variant="body2" color="text.secondary">
          このゲームのキャッチコピーはまだ登録されていません。
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 3, sm: 4 } }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1 
      }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>キャッチコピー</Typography>
        <PhraseUploadButton 
          gameId={gameId}
          onUploadComplete={fetchPhrases}
          onError={(error) => console.error(error)}
        />
      </Box>
      <Grid container spacing={2}>
        {phrases.map((phrase) => (
          <Grid item xs={12} key={phrase.id}>
            <PhraseCard 
              onClick={() => handlePhraseClick(phrase.id)}
              sx={{ cursor: 'pointer' }}
            >
              <CardContent sx={{ pt: 3, pb: '16px !important' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                  <FormatQuoteIcon sx={{ transform: 'scale(-1, -1)', color: 'text.secondary', fontSize: '1.2rem' }} />
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontWeight: 500,
                      lineHeight: 1.6,
                      pr: phrase.source ? 8 : 0
                    }}
                  >
                    {phrase.content}
                  </Typography>
                </Box>
                {phrase.context && (
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                      mt: 1,
                      fontSize: '0.8rem',
                      pl: '28px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      lineHeight: 1.4
                    }}
                  >
                    {phrase.context}
                  </Typography>
                )}
              </CardContent>
              {phrase.source && (
                <SourceChip 
                  label={phrase.source} 
                  size="small"
                />
              )}
            </PhraseCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GamePhraseSection; 