import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { checkProfileCompletion, API_BASE_URL } from '../../services/api';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import Dialog from '../dialogs/Dialog';

interface GoogleLoginButtonProps {
  onLoginSuccess: (user: any) => void;
  redirectPath: string;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onLoginSuccess, redirectPath }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Googleユーザー情報を取得
        const userInfoResponse = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );

        // バックエンドに送信
        const response = await axios.post(`${API_BASE_URL}/auth/google/login/token/`, {
          access_token: tokenResponse.access_token,
          user_info: userInfoResponse.data
        });
        
        // トークンを保存
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        
        // ユーザー情報を保存
        localStorage.setItem('user', JSON.stringify(response.data.user));

        // プロフィール完成度チェック
        const isProfileComplete = await checkProfileCompletion();
        
        // 完成度に基づいて遷移
        if (!isProfileComplete) {
          navigate('/profile/settings', { replace: true });
        } else {
          navigate(redirectPath, { replace: true });
        }

        // ヘッダーの状態を更新
        onLoginSuccess(response.data.user);
      } catch (error: any) {
        console.error('Google login failed', error);
        setError(error.response?.data?.error || 'ログインに失敗しました');
      }
    },
    onError: () => {
      setError('ログインに失敗しました');
    }
  });

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => login()}
        startIcon={<GoogleIcon />}
        fullWidth
        sx={{
          backgroundColor: '#ffffff',
          color: '#000000',
          border: '1px solid #dadce0',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#f8f9fa',
            boxShadow: '0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)'
          },
          mt: 2,
          textTransform: 'none',
          fontWeight: 500
        }}
      >
        Googleでログイン
      </Button>
      
      <Dialog
        open={!!error}
        onClose={handleCloseError}
        title="エラー"
        content={error || ''}
      />
    </>
  );
};

export default GoogleLoginButton;