import api from './api';

export const getCurrentUserId = async () => {
  try {
    const response = await api.get('/user/id');
    return response.data.id;
  } catch (error) {
    console.error('Failed to get current user ID:', error);
    return null;
  }
};

export const isAuthenticated = async () => {
  try {
    const token = localStorage.getItem('token');
    return !!token; // tokenが存在すればtrueを返す
  } catch (error) {
    return false;
  }
};

export const isSuperuser = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    
    const response = await api.get('/user/is-superuser/');
    return response.data.is_superuser;
  } catch (error) {
    console.error('Failed to check superuser status:', error);
    return false;
  }
};
