import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardMedia, Skeleton, Dialog, Button, Paper, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { getFavoriteGames, addFavoriteGame, FavoriteGame, reorderFavoriteGames, removeFavoriteGame } from '../../services/contents_api';
import { GameSearch } from '../common/GameSearch';
import AddIcon from '@mui/icons-material/Add';
import { SortableGameGrid } from '../common/SortableGameGrid';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface FavoriteGamesWidgetProps {
  username?: string;
}

const EmptySlot: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Card 
    onClick={onClick}
    sx={{ 
      position: 'relative',
      paddingTop: '133%',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      }
    }}
  >
    <IconButton
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'text.secondary'
      }}
    >
      <AddIcon />
    </IconButton>
  </Card>
);

// 共通のPaperスタイルを定義
const commonPaperStyle = {
  p: 2.5,
  height: '100%',
  borderRadius: 2,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  background: 'background.paper',
  backdropFilter: 'blur(10px)',
  transition: 'all 0.2s ease',
  position: 'relative',
};

const FavoriteGamesWidget: React.FC<FavoriteGamesWidgetProps> = ({ username }) => {
  const [favorites, setFavorites] = useState<FavoriteGame[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<number>(0);
  const [editMode, setEditMode] = useState(false);
  const [editingFavorites, setEditingFavorites] = useState<FavoriteGame[]>([]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const data = await getFavoriteGames(username);
        setFavorites(data);
      } catch (error) {
        console.error('お気に入りゲームの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [username]);

  useEffect(() => {
    if (editMode) {
      setEditingFavorites([...favorites]);
    }
  }, [editMode]);

  const handleAddGame = async (game: { game_id: number }) => {
    try {
      const order = selectedSlot;
      const newFavorite = await addFavoriteGame(game.game_id, order);
      setFavorites(prev => [...prev, newFavorite].sort((a, b) => a.order - b.order));
      setSearchOpen(false);
    } catch (error) {
      console.error('お気に入りゲームの追加に失敗しました:', error);
    }
  };

  const handleSlotClick = (index: number) => {
    setSelectedSlot(index);
    setSearchOpen(true);
  };

  const handleDelete = (gameId: number) => {
    setEditingFavorites(prev => prev.filter(f => f.game_id !== gameId));
  };

  const handleReorder = (newItems: any[]) => {
    setEditingFavorites(prev => newItems.map((item, index) => ({
      ...prev.find(f => f.game_id === item.id)!,
      order: index
    })));
  };

  const handleEditComplete = async () => {
    try {
      const deletedItems = favorites.filter(f => 
        !editingFavorites.some(ef => ef.game_id === f.game_id)
      );

      const reorderedItems = editingFavorites.map((item, index) => ({
        id: item.game_id,
        order: index
      }));

      for (const item of deletedItems) {
        await removeFavoriteGame(item.game_id);
      }

      if (reorderedItems.length > 0) {
        await reorderFavoriteGames(reorderedItems);
      }

      setFavorites(editingFavorites);
      setEditMode(false);
    } catch (error) {
      console.error('お気に入りの更新に失敗しました:', error);
      setEditingFavorites([...favorites]);
    }
  };

  if (loading) {
    return (
      <Paper elevation={0} sx={commonPaperStyle}>
        <Typography variant="subtitle2" color="text.secondary">ベストゲーム</Typography>
        <Grid container spacing={{ xs: 1.5, sm: 2 }}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={4} sm={2} key={index}>
              <Box sx={{ position: 'relative', paddingTop: '133%' }}>
                <Skeleton 
                  variant="rectangular" 
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 1
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper elevation={0} sx={commonPaperStyle}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle2" color="text.secondary" lineHeight={1}>
          {username ? `@${username}のベストゲーム` : 'ベストゲーム'}
        </Typography>
        {!username && favorites.length > 0 && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {editMode ? (
              <>
                <Button
                  startIcon={<CloseIcon />}
                  size="small"
                  variant="outlined"
                  onClick={() => setEditMode(false)}
                  color="error"
                >
                  キャンセル
                </Button>
                <Button
                  startIcon={<DoneIcon />}
                  size="small"
                  variant="outlined"
                  onClick={handleEditComplete}
                  color="success"
                >
                  完了
                </Button>
              </>
            ) : (
              <Button
                startIcon={<EditIcon />}
                size="small"
                variant="outlined"
                onClick={() => setEditMode(true)}
                color="primary"
              >
                編集
              </Button>
            )}
          </Box>
        )}
      </Box>

      {editMode && (
        <Box 
          sx={{ 
            p: 2, 
            mb: 2,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
            border: '1px solid rgba(25, 118, 210, 0.2)',
            borderRadius: 1
          }}
        >
          <Typography variant="body2" color="primary">
            タイトルの削除と、ドラッグ＆ドロップで並び替えができます
          </Typography>
        </Box>
      )}

      {editMode ? (
        <SortableGameGrid
          items={editingFavorites.map(f => ({
            id: f.game_id,
            name: f.game_japanese_name || f.game_name,
            cover_url: f.game_cover,
            order: f.order
          }))}
          onReorder={handleReorder}
          onDelete={handleDelete}
          maxItems={6}
          gridTemplateColumns={{
            xs: 'repeat(3, 1fr)',
            sm: 'repeat(6, 1fr)'
          }}
        />
      ) : (
        <>
          {favorites.length === 0 && !username ? (
            <Grid container spacing={{ xs: 1.5, sm: 2 }}>
              <Grid item xs={4} sm={2}>
                <EmptySlot onClick={() => handleSlotClick(0)} />
              </Grid>
            </Grid>
          ) : favorites.length === 0 ? (
            <Box 
              sx={{ 
                p: 3, 
                textAlign: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.02)',
                borderRadius: 1
              }}
            >
              <Typography variant="body2" color="text.secondary">
                まだお気に入りゲームが登録されていません
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={{ xs: 1.5, sm: 2 }}>
              {favorites.map((favorite) => (
                <Grid item xs={4} sm={2} key={favorite.id}>
                  <Link 
                    to={`/game/${favorite.game_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Card 
                      sx={{ 
                        position: 'relative',
                        paddingTop: '133%',
                        backgroundColor: 'rgba(255, 255, 255, 0.02)',
                        borderRadius: 1
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={favorite.game_cover || '/image/no_image.png'}
                        alt={favorite.game_japanese_name || favorite.game_name}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 1
                        }}
                      />
                    </Card>
                  </Link>
                </Grid>
              ))}
              
              {!username && [...Array(6 - favorites.length)].map((_, index) => (
                <Grid item xs={4} sm={2} key={`empty-${index}`}>
                  <EmptySlot onClick={() => handleSlotClick(favorites.length + index)} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      <Dialog 
        open={searchOpen} 
        onClose={() => setSearchOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>お気に入りゲームを追加</Typography>
          <GameSearch 
            onSelect={handleAddGame}
            placeholder="追加したいゲームを検索..."
          />
        </Box>
      </Dialog>
    </Paper>
  );
};

export default FavoriteGamesWidget;
