import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { Game } from '../types/Game';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

interface LightweightGame {
  game_id: number;
  name: string;
  japanese_name?: string | null;
  cover?: string | null;
}

interface LightweightGameCardProps {
  game: LightweightGame;
  hideTitle?: boolean;
  openInNewTab?: boolean;
}

const LightweightGameCard: React.FC<LightweightGameCardProps> = ({ game, hideTitle = false, openInNewTab = false }) => {
  return (
    <Box
      component={RouterLink}
      to={`/game/${game.game_id}`}
      target={openInNewTab ? '_blank' : undefined}
      sx={{
        textDecoration: 'none',
        display: 'block',
        height: '100%',
      }}
    >
      <Card 
        elevation={0}
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: { xs: 'none', lg: 'translateY(-4px)' },
            boxShadow: 'none',
          },
          backgroundColor: 'background.default',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <CardMedia
          component="div"
          sx={{
            height: 0,
            paddingTop: '133.33%', // 3:4のアスペクト比
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '8px',
          }}
          image={game.cover || '/image/no_image.png'}
        />
        {!hideTitle && (
          <CardContent sx={{ flexGrow: 1, p: 0, pt: 1, backgroundColor: 'background.default', '&:last-child': { pb: 1.5 } }}>
            <Typography variant="subtitle2" component="h2" noWrap sx={{ lineHeight: 1, fontSize: '0.6rem', fontWeight: '500', mb: 0 }}>
              {game.japanese_name || game.name}
            </Typography>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default LightweightGameCard; 