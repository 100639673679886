import React, { useState } from 'react';
import { 
  Button,
  Tooltip, 
  Snackbar,
  Box,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import XIcon from '@mui/icons-material/X';
import PopoverMenu from './PopoverMenu';

interface IconOnlyBreakpoints {
  xs?: boolean;
  md?: boolean;
}

interface ShareButtonsProps {
  text: string;
  url: string;
  hashtags?: string[];
  buttonText?: string;
  iconSize?: number;
  fontSize?: number;
  hideIcon?: boolean;
  matchIconColor?: boolean;
  iconOnly?: boolean | { xs: boolean; md: boolean };
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ 
  text, 
  url, 
  hashtags = [],
  buttonText,
  iconSize = 18,
  fontSize = 14,
  hideIcon = false,
  matchIconColor = false,
  iconOnly = false
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const fullUrl = `${process.env.REACT_APP_FRONTEND_URL}${url}`;

  const isIconOnly = (breakpoint: 'xs' | 'md'): boolean => {
    if (typeof iconOnly === 'boolean') return iconOnly;
    return iconOnly[breakpoint];
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(fullUrl);
    setOpenSnackbar(true);
    handleClose();
  };

  const handleShareX = () => {
    const hashtagsParam = hashtags.length > 0 ? `&hashtags=${encodeURIComponent(hashtags.join(','))}` : '';
    const xShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(fullUrl)}${hashtagsParam}`;
    window.open(xShareUrl, '_blank');
    handleClose();
  };

  const menuOptions = [
    {
      icon: <XIcon />,
      label: 'Xでシェア',
      onClick: handleShareX
    },
    {
      icon: <ContentCopyIcon />,
      label: 'URLをコピー',
      onClick: handleCopyLink
    }
  ];

  return (
    <>
      <Tooltip title="シェア">
        <Button
          onClick={handleClick}
          size="small"
          sx={{ 
            minWidth: {
              xs: isIconOnly('xs') ? '32px' : 'auto',
              md: isIconOnly('md') ? '32px' : 'auto',
            },
            p: {
              xs: isIconOnly('xs') ? '4px' : '2px 8px',
              md: isIconOnly('md') ? '4px' : '2px 8px',
            },
            fontSize: `${fontSize}px`,
            textTransform: 'none',
            lineHeight: '1',
            borderRadius: '4px',
            color: 'text.secondary',
            backgroundColor: 'action.hover',
            '&:hover': {
              backgroundColor: 'action.selected',
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center'
          }}>
            <ShareIcon sx={{ 
              fontSize: `${iconSize}px`, 
              color: matchIconColor ? 'inherit' : 'primary.main',
            }} />
            <Box sx={{ 
              display: { 
                xs: isIconOnly('xs') ? 'none' : 'block',
                md: isIconOnly('md') ? 'none' : 'block'
              },
              ml: 0.5
            }}>
              {buttonText || '共有'}
            </Box>
          </Box>
        </Button>
      </Tooltip>

      <PopoverMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        title="共有オプション"
        options={menuOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="URLをコピーしました"
      />
    </>
  );
};

export default ShareButtons;
