import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import GameReviewDialog from './GameReviewDialog';
import { Platform } from '../types/Game';
import { GameReviewData } from '../types/Review';

interface GameReviewButtonProps {
  gameId: number;
  initialReviewData: GameReviewData;
  platforms: Platform[];
  releaseDate?: string;
  userBirthDate?: string;
  onReviewChange: (reviewData: GameReviewData) => void;
  openDialog?: boolean;
  onDialogClose?: () => void;
}

const GameReviewButton: React.FC<GameReviewButtonProps> = ({
  gameId,
  initialReviewData,
  platforms,
  releaseDate,
  userBirthDate,
  onReviewChange,
  openDialog: externalOpenDialog,
  onDialogClose
}) => {
  const [internalOpenDialog, setInternalOpenDialog] = useState(false);
  
  const isDialogOpen = externalOpenDialog || internalOpenDialog;

  const handleClose = () => {
    setInternalOpenDialog(false);
    onDialogClose?.();
  };

  return (
    <>
      <Tooltip title="レビューを書く" arrow>
        <IconButton
          onClick={() => setInternalOpenDialog(true)}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(4px)',
            border: '1px solid rgba(255, 255, 255, 0.08)',
            borderRadius: '12px',
            padding: '7px',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
              transform: 'translateY(-1px)',
            },
          }}
        >
          <CreateIcon fontSize="small" sx={{ color: 'primary.main' }} />
        </IconButton>
      </Tooltip>

      <GameReviewDialog
        open={isDialogOpen}
        onClose={handleClose}
        gameId={gameId}
        initialData={initialReviewData}
        platforms={platforms}
        releaseDate={releaseDate}
        userBirthDate={userBirthDate}
        onReviewSaved={onReviewChange}
      />
    </>
  );
};

export default GameReviewButton; 