import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, CircularProgress, Box } from '@mui/material';
import TriviaEditor from '../../../components/trivia/TriviaEditor';
import { updateGameTrivia, getGameTriviaDetail } from '../../../services/contents_api';
import { isAuthenticated } from '../../../services/auth';

const GameTriviaEditPage: React.FC = () => {
  const { triviaId } = useParams<{ triviaId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<{
    title: string;
    content: string;
    source: string | null;
  } | null>(null);

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      const authenticated = await isAuthenticated();
      if (!authenticated) {
        navigate('/login', { 
          state: { from: `/trivia/${triviaId}/edit` }
        });
        return;
      }

      try {
        if (!triviaId) throw new Error('トリビアIDが指定されていません');
        const data = await getGameTriviaDetail(Number(triviaId));
        setInitialData({
          title: data.title,
          content: data.content,
          source: data.source,
        });
      } catch (error) {
        console.error('トリビアの取得に失敗しました:', error);
        setError('トリビアの取得に失敗しました');
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };

    checkAuthAndFetchData();
  }, [triviaId, navigate]);

  const handleSubmit = async (data: { title: string; content: string; source?: string }) => {
    try {
      if (!triviaId) throw new Error('トリビアIDが指定されていません');
      await updateGameTrivia(Number(triviaId), data);
      navigate(-1);
    } catch (error) {
      console.error('トリビアの更新に失敗しました:', error);
      setError('トリビアの更新に失敗しました');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !initialData) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography color="error">{error || 'トリビアが見つかりませんでした。'}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 16 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          トリビアを編集
        </Typography>
        <TriviaEditor
          triviaId={Number(triviaId)}
          initialTitle={initialData.title}
          initialContent={initialData.content}
          initialSource={initialData.source}
          onSubmit={handleSubmit}
          onCancel={() => navigate(-1)}
        />
      </Paper>
    </Container>
  );
};

export default GameTriviaEditPage; 