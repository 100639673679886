import React, { useState } from 'react';
import { Box, Link, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface ExpandableProps {
  children: React.ReactNode;
  maxHeight?: number;
  maxLines?: number;
  expandLabel?: string;
  collapseLabel?: string;
  expandButtonStyle?: 'text' | 'icon' | 'pill';
  iconSize?: number;
  iconPadding?: number;
  iconColor?: string;
  iconBgColor?: string;
  pillTextAlign?: 'left' | 'center' | 'right';
}

const ExpandableContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maxHeight' && prop !== 'maxLines' && prop !== 'isExpanded'
})<{ maxHeight?: number; maxLines?: number; isExpanded: boolean }>(
  ({ maxHeight, maxLines, isExpanded }) => ({
    position: 'relative',
    ...(maxHeight && {
      maxHeight: isExpanded ? 'none' : maxHeight,
    }),
    ...(maxLines && {
      display: isExpanded ? 'block' : '-webkit-box',
      WebkitLineClamp: maxLines,
      WebkitBoxOrient: 'vertical',
    }),
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  })
);

const TextExpandButton = styled(Link)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  cursor: 'pointer',
  fontSize: '0.65rem',
  fontWeight: 'normal',
  lineHeight: '1.5',
  backgroundColor: 'background.paper',
  paddingLeft: '8px',
  zIndex: 1,
  transform: 'translateY(100%)',
});

const IconExpandButton = styled(IconButton)<{ padding: number }>(({ padding }) => ({
  position: 'absolute',
  bottom: padding,
  right: padding,
  padding: padding,
  minWidth: 'auto',
  minHeight: 'auto',
  zIndex: 1,
  transform: 'translateY(50%)',
}));

const PillExpandButton = styled(IconButton)<{ expanded?: boolean }>(({ theme, expanded }) => ({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  paddingTop: '0px',
  color: '#A7A7A7',
  zIndex: 1,
  width: '100%',
  height: '28px',
  borderRadius: '0',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: '#c7c7c7',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-56px',
    left: 0,
    right: 0,
    height: '56px',
    background: !expanded ? 'linear-gradient(to bottom, transparent, #141414)' : 'none',
  }
}));

const Expandable: React.FC<ExpandableProps> = ({
  children,
  maxHeight,
  maxLines,
  expandLabel = '全て表示',
  collapseLabel = '折りたたむ',
  expandButtonStyle = 'text',
  iconSize = 20,
  iconPadding = 4,
  iconColor = 'primary',
  iconBgColor = 'transparent',
  pillTextAlign = 'center',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [needsExpand, setNeedsExpand] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const checkNeedsExpand = () => {
      if (contentRef.current) {
        if (maxHeight) {
          setNeedsExpand(contentRef.current.scrollHeight > maxHeight);
        } else if (maxLines) {
          const element = contentRef.current;
          const styles = window.getComputedStyle(element);
          const lineHeight = parseFloat(styles.lineHeight);
          const height = element.offsetHeight;
          const maxHeightValue = maxLines * lineHeight;
          
          // スクロール高さが表示高さより大きい場合、または
          // 計算された最大高さより実際の高さが大きい場合に展開が必要
          setNeedsExpand(
            element.scrollHeight > element.clientHeight ||
            height > maxHeightValue
          );
        }
      }
    };

    checkNeedsExpand();

    // リサイズイベントのリスナーを追加
    window.addEventListener('resize', checkNeedsExpand);
    
    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', checkNeedsExpand);
    };
  }, [children, maxHeight, maxLines]);

  const handleExpandToggle = () => {
    if (isExpanded && contentRef.current) {
      // 折りたたむ時は、コンテンツの先頭位置までスクロール
      contentRef.current.scrollIntoView({ block: 'start' });
    }
    setIsExpanded(!isExpanded);
  };

  const renderExpandButton = () => {
    switch (expandButtonStyle) {
      case 'icon':
        return (
          <IconExpandButton
            onClick={handleExpandToggle}
            padding={iconPadding}
            sx={{
              color: iconColor,
              bgcolor: iconBgColor,
              '&:hover': {
                bgcolor: iconBgColor === 'transparent' ? 'rgba(0, 0, 0, 0.04)' : iconBgColor,
              },
            }}
          >
            {isExpanded ? (
              <ExpandLessIcon sx={{ fontSize: iconSize }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: iconSize }} />
            )}
          </IconExpandButton>
        );
      case 'pill':
        return (
          <PillExpandButton
            expanded={isExpanded}
            onClick={handleExpandToggle}
            size="small"
            sx={{
              display: 'flex',
              justifyContent: pillTextAlign === 'left' ? 'flex-start' : 
                            pillTextAlign === 'right' ? 'flex-end' : 'center',
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                fontSize: iconSize,
                marginRight: '4px',
                order: pillTextAlign === 'right' ? 2 : 0,
              },
              fontSize: '0.7rem',
              lineHeight: '1',
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {isExpanded ? collapseLabel : expandLabel}
          </PillExpandButton>
        );
      default:
        return (
          <TextExpandButton
            onClick={handleExpandToggle}
            color="primary"
          >
            {isExpanded ? collapseLabel : expandLabel}
          </TextExpandButton>
        );
    }
  };

  return (
    <Box sx={{ 
      position: 'relative',
      pb: needsExpand ? (expandButtonStyle === 'icon' ? 1 : 4) : 0,
    }}>
      <ExpandableContent
        ref={contentRef}
        maxHeight={maxHeight}
        maxLines={maxLines}
        isExpanded={isExpanded}
      >
        {children}
      </ExpandableContent>
      {needsExpand && renderExpandButton()}
    </Box>
  );
};

export default Expandable;