import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ConfirmationDialog from './dialogs/ConfirmationDialog';

interface LifeStageSelectorProps {
  birthDate: string | undefined | null;
  targetDate: string | undefined;
  onSelect: (date: string) => void;
  mode?: 'start' | 'end';
  startDate?: string | null;
}

const calculateLifeStage = (birthDate: string, targetDate: string): string => {
  const birth = new Date(birthDate);
  const target = new Date(targetDate);
  const age = Math.floor((target.getTime() - birth.getTime()) / (365.25 * 24 * 60 * 60 * 1000));

  if (age < 6) return `${age}歳`;  // 小学生未満は年齢表示
  if (age < 12) return `小学${Math.min(Math.max(Math.floor(age - 5), 1), 6)}年`;
  if (age < 15) return `中学${Math.min(Math.max(Math.floor(age - 11), 1), 3)}年`;
  if (age < 18) return `高校${Math.min(Math.max(Math.floor(age - 14), 1), 3)}年`;
  if (age < 22) return `大学${Math.min(Math.max(Math.floor(age - 17), 1), 4)}年`;
  return `${age}歳`;
};

const getSchoolYearDates = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);
  
  // 最初の4月1日を見つける
  currentDate.setMonth(3); // 4月
  currentDate.setDate(1);  // 1日
  
  // 発売日より前の場合は発売日まで進める
  if (currentDate < startDate) {
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }

  return dates;
};

const LifeStageSelector: React.FC<LifeStageSelectorProps> = ({
  birthDate,
  targetDate,
  onSelect,
  mode = 'start',
  startDate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!birthDate) {
      setShowDialog(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLifeStageOptions = (): { label: string; date: string }[] => {
    if (!birthDate || !targetDate) return [];

    const birthDateTime = new Date(birthDate);
    const releaseDateTime = new Date(targetDate);
    const currentDate = new Date();
    const options: { label: string; date: string }[] = [];

    // 完了日モードの場合は開始日以降の日付のみを表示
    const startDateTime = mode === 'end' && startDate ? new Date(startDate) : releaseDateTime;

    // 今日を選択肢に追加
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setMinutes(-currentDate.getTimezoneOffset());
    if (currentDate >= startDateTime) {
      options.push({
        label: `今日 (${currentDate.getFullYear()}年)`,
        date: currentDate.toISOString().split('T')[0]
      });
    }

    // 発売日も選択肢に含める（4歳以上の場合のみ）
    if (mode === 'start' || (mode === 'end' && releaseDateTime >= startDateTime)) {
      const releaseAge = Math.floor((releaseDateTime.getTime() - birthDateTime.getTime()) / (365.25 * 24 * 60 * 60 * 1000));
      if (releaseAge > 3) {
        options.push({
          label: `発売日 (${releaseDateTime.getFullYear()}年)`,
          date: targetDate.split('T')[0]
        });
      }
    }

    // 発売日以降の4月1日の日付を取得
    const schoolYearDates = getSchoolYearDates(startDateTime, currentDate);

    // 各日付でのライフステージを計算（4歳以上の場合のみ）
    schoolYearDates.forEach(date => {
      const age = Math.floor((date.getTime() - birthDateTime.getTime()) / (365.25 * 24 * 60 * 60 * 1000));
      if (date > birthDateTime && age > 3) {
        const stage = calculateLifeStage(birthDate, date.toISOString());
        options.push({
          label: `${stage} (${date.getFullYear()}年)`,
          date: date.toISOString().split('T')[0]
        });
      }
    });

    return options;
  };

  if (!targetDate) return null;

  return (
    <>
      <Button
        size="small"
        startIcon={<SchoolIcon />}
        onClick={handleClick}
        variant="outlined"
        sx={{
          minWidth: 'auto',
          height: '40px',
          borderColor: 'rgba(255, 255, 255, 0.23)',
          color: 'text.primary',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            borderColor: 'rgba(255, 255, 255, 0.23)',
          }
        }}
      >
        {window.innerWidth > 768 ? 'ライフステージから選択' : 'ライフステージ'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
          },
        }}
      >
        {getLifeStageOptions().map((option) => (
          <MenuItem
            key={option.date}
            onClick={() => {
              onSelect(option.date);
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <ConfirmationDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={() => setShowDialog(false)}
        title="生年月日が必要です"
        message="ライフステージセレクターを使用するには、生年月日の設定が必要です。プロフィール設定から生年月日を設定してください。"
        confirmText="OK"
        cancelText=""
      />
    </>
  );
};

export default LifeStageSelector;