import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { addGameTrivia } from '../../services/contents_api';
import { isAuthenticated } from '../../services/auth';
import LoginRequiredDialog from '../dialogs/LoginRequiredDialog';
import { useLocation } from 'react-router-dom';

interface TriviaUploadButtonProps {
  gameId: number;
  onUploadComplete?: () => void;
  onError?: (error: string) => void;
}

const TriviaUploadButton: React.FC<TriviaUploadButtonProps> = ({ gameId, onUploadComplete, onError }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [source, setSource] = useState('');
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const location = useLocation();

  const handleSubmit = async () => {
    if (!title || !content) return;

    setLoading(true);
    try {
      await addGameTrivia({
        game_id: gameId,
        title,
        content,
        source: source || undefined,
      });

      if (onUploadComplete) {
        onUploadComplete();
      }
      setOpen(false);
      resetForm();
    } catch (error) {
      console.error('トリビアの追加に失敗しました:', error);
      if (onError) {
        onError('トリビアの追加に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setSource('');
  };

  const handleAddClick = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setShowLoginDialog(true);
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        sx={{ 
          borderRadius: '12px',
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        add
      </Button>

      <LoginRequiredDialog
        open={showLoginDialog}
        onClose={() => setShowLoginDialog(false)}
        redirectPath={location.pathname}
      />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          トリビアの追加
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="タイトル"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            required
            placeholder="トリビアのタイトルを入力してください"
          />
          <TextField
            fullWidth
            label="説明"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            margin="normal"
            required
            multiline
            rows={3}
            placeholder="ゲームに関する豆知識や裏話を入力してください"
          />

          {process.env.NODE_ENV === 'development' && (
            <TextField
              fullWidth
              label="出典"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              margin="normal"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!title || !content || loading}
          >
            {loading ? '追加中...' : '追加'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TriviaUploadButton; 