import React from 'react';
import { Box, Typography, Paper, Container, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';

const InstallPwaPage: React.FC = () => {
  const theme = useTheme();

  const InstallStep = ({ icon, title, description }: { icon: React.ReactNode, title: string, description: string }) => (
    <Paper
      sx={{
        p: 3,
        mb: 2,
        backgroundColor: 'background.paper',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        '&:hover': {
          borderColor: 'primary.main',
          transform: 'translateY(-2px)',
          transition: 'all 0.2s ease-in-out'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
        <Box sx={{ 
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          borderRadius: 1,
          bgcolor: `${theme.palette.primary.main}11`
        }}>
          {icon}
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom sx={{ fontSize: '1.1rem' }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-line' }}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );

  return (
    <>
      <Helmet>
        <title>PWAアプリのインストール | {SITE_NAME}</title>
      </Helmet>

      <Container maxWidth="md">
        <Box sx={{ 
          mb: 4, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: 'rgba(255, 255, 255, 0.01)',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          px: 2,
          py: 4,
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: '-100vw',
            right: '-100vw',
            bottom: 0,
            background: `linear-gradient(135deg, 
              ${theme.palette.mode === 'dark' 
                ? 'rgba(55, 65, 81, 0.1) 0%, rgba(17, 24, 39, 0.7) 100%' 
                : 'rgba(229, 231, 235, 0.1) 0%, rgba(156, 163, 175, 0.1) 100%'
              })`,
            zIndex: -1
          }
        }}>
          <Box sx={{
            position: 'relative',
            mb: 3,
          }}>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '140px',
              height: '140px',
              borderRadius: '50%',
            }} />
            <img 
              src="/logo512.png" 
              alt="アプリアイコン" 
              style={{ 
                position: 'relative',
                borderRadius: '16px', 
                width: '64px', 
                height: '64px',
              }} 
            />
          </Box>
          <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 600,
              textAlign: 'center',
              mb: 2
            }}
          >
            アプリのインストール /PWA
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary" 
            paragraph 
            sx={{ 
              maxWidth: '600px',
              textAlign: 'center',
              lineHeight: 1.8
            }}
          >
            PULSEはスマートフォンにPWAとしてインストールすることで、ネイティブアプリのように快適に利用できます。
            ホーム画面のアイコンからワンタップで起動でき、フルスクリーンで表示されるため、ブラウザの煩わしさがなくなります。
          </Typography>
        </Box>

        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            iOSの場合
          </Typography>
          <InstallStep
            icon={<IosShareIcon />}
            title="1. 共有メニューを開く"
            description="ブラウザの下部メニューから「共有」ボタンをタップします。"
          />
          <InstallStep
            icon={<AddToHomeScreenIcon />}
            title="2. ホーム画面に追加"
            description="共有メニューから「ホーム画面に追加」を選択します。"
          />
          <InstallStep
            icon={<DownloadIcon />}
            title="3. インストール完了"
            description="「追加」をタップすると、ホーム画面にPULSEのアイコンが追加され、PWAのインストールが完了します。"
          />
        </Box>

        <Box>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            Androidの場合
          </Typography>
          <InstallStep
            icon={<AddToHomeScreenIcon />}
            title="1. アプリをインストール"
            description="メニュー、またはブラウザごとに特定の位置に表示される「アプリをインストール」を選択します。
            表示されない場合は、ブラウザのメニューから「ホーム画面に追加」を選択してください。"
          />
          <InstallStep
            icon={<DownloadIcon />}
            title="2. インストール完了"
            description="確認ダイアログで「インストール」をタップすると、ホーム画面にPULSEのアイコンが追加され、PWAのインストールが完了します。"
          />
        </Box>
      </Container>
    </>
  );
};

export default InstallPwaPage;