import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { History as HistoryIcon } from '@mui/icons-material';
import { getGameTriviaHistory } from '../../services/contents_api';
import TriviaEditConfirmDialog from './TriviaEditConfirmDialog';

interface TriviaHistoryProps {
  triviaId: number;
  currentTrivia: {
    title: string;
    content: string;
    source: string | null;
  };
}

interface HistoryItem {
  id: number;
  editor: string;
  action_type: number;
  title: string;
  content: string;
  source: string | null;
  created_at: string;
}

const TriviaHistory: React.FC<TriviaHistoryProps> = ({ triviaId, currentTrivia }) => {
  const [histories, setHistories] = useState<HistoryItem[]>([]);
  const [selectedHistory, setSelectedHistory] = useState<HistoryItem | null>(null);
  const [diffDialogOpen, setDiffDialogOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const historyData = await getGameTriviaHistory(triviaId);
        setHistories(historyData);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      }
    };

    loadData();
  }, [triviaId]);

  const handleHistoryClick = (history: HistoryItem) => {
    setSelectedHistory(history);
    setDiffDialogOpen(true);
  };

  const getComparisonData = (currentIndex: number) => {
    const currentHistory = histories[currentIndex];
    
    if (currentIndex === 0) {
      return {
        originalData: {
          title: currentHistory.title,
          content: currentHistory.content,
          source: currentHistory.source
        },
        newData: {
          title: currentTrivia.title,
          content: currentTrivia.content,
          source: currentTrivia.source
        }
      };
    }

    const nextHistory = histories[currentIndex - 1];
    return {
      originalData: {
        title: currentHistory.title,
        content: currentHistory.content,
        source: currentHistory.source
      },
      newData: {
        title: nextHistory.title,
        content: nextHistory.content,
        source: nextHistory.source
      }
    };
  };

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
        編集履歴
      </Typography>
      {histories.map((history, index) => (
        <Box 
          key={history.id}
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            mb: 1,
            '&:hover': { bgcolor: 'action.hover' },
            cursor: 'pointer'
          }}
          onClick={() => handleHistoryClick(history)}
        >
          <IconButton size="small">
            <HistoryIcon fontSize="small" />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography variant="body2">
              {history.editor} が{history.action_type === 1 ? '作成' : '編集'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {new Date(history.created_at).toLocaleString('ja-JP')}
            </Typography>
          </Box>
        </Box>
      ))}

      {selectedHistory && (
        <TriviaEditConfirmDialog
          open={diffDialogOpen}
          onClose={() => setDiffDialogOpen(false)}
          onConfirm={() => setDiffDialogOpen(false)}
          {...getComparisonData(histories.findIndex(h => h.id === selectedHistory.id))}
          readOnly
        />
      )}
    </Box>
  );
};

export default TriviaHistory; 